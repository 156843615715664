import { useState, useEffect } from "react";

const useChainId = () => {
  const [checkChainId, setCheckChainId] = useState("");

  useEffect(() => {
    const chainId = window.ethereum?.chainId;
    if (chainId === "0x1" || chainId === "0xa") {
      setCheckChainId(chainId);
    }
  }, []);

  return checkChainId;
};

export default useChainId;
