import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./UniV2WithdrawSection.scss";
import { uniV2UnStakeAction } from "../../redux/actions/uniV2StakingActions/uniV2UnStakeAction";
import Loading from "../GloBal/Loading";
import useWalletProvider from "../../hooks/useWalletProvider";
import useChainId from "../../hooks/useChainId";
import { ethers } from "ethers";

const UniV2WithdrawSection = () => {
  const dispatch = useDispatch();
  const walletProvider = useWalletProvider();
  const checkChainId = useChainId();
  const [uniV2WithdrawAmount, setUniV2WithdrawAmount] = useState("");

  const { account } = useSelector((state) => state.account);
  const { uniV2StakedAmount, uniV2TotalSupply } = useSelector((state) => state.uniV2View);

  const changeUsdcMaxWithdrawAmount = () => {
    setUniV2WithdrawAmount(uniV2StakedAmount);
  };

  const changeUsdcRakis6Amount = (e) => {
    const pattern = /^(\d{0,4}([.]\d{0,18})?)?$/;
    if (pattern.test(e.target.value)) {
      setUniV2WithdrawAmount(e.target.value);
    }
  };

  const setUsdcRakis6UnStake = () => {
    let hanRakis6UnStakeAmount = document.getElementById("maxUniV2UnStakeAmount").value;
    const hanRakis6UnStakeNum = ethers.utils.parseEther(String(hanRakis6UnStakeAmount));
    dispatch(uniV2UnStakeAction.uniV2UnStakeAct(account, hanRakis6UnStakeNum, walletProvider));
  };

  return (
    <>
      {checkChainId === "0x1" ? (
        <>
          <>
            <div className='uniV2-Staking-Withdraw-Quaota-Section'>
              <p>TOTAL STAKED : {uniV2TotalSupply}</p>
            </div>
            <div className='uniV2-Staking-Withdraw-StakeAmount-Section'>
              <p>STAKED : {uniV2StakedAmount} </p>
            </div>
          </>

          <div className='uniV2-Staking-Withdraw-AmountSection'>
            <input
              type='number'
              step='0.000000000000000001'
              id='maxUniV2UnStakeAmount'
              placeholder='0'
              onChange={changeUsdcRakis6Amount}
              value={uniV2WithdrawAmount}
            ></input>
            <p>UNI-V2</p>
            <button className='uniV2-Staking-Withdraw-AmountMaxBtn' onClick={changeUsdcMaxWithdrawAmount}>
              Max
            </button>
            {/* <button className="uniV2-Staking-Withdraw-AmountMaxBtn" onClick={changeMaxHanWithdrawAmount}>
             Max
         </button> */}
          </div>

          <div className='uniV2-Staking-WithDraw-BtnContainer'>
            <div className='uniV2-Staking-Withdraw-Can-BtnSection'>
              {uniV2WithdrawAmount === "" ? (
                <button className='uniV2-Staking-Withdraw-SelectBtn'>ENTER AMOUNT</button>
              ) : uniV2StakedAmount === "0" ||
                parseFloat(uniV2WithdrawAmount) > parseFloat(uniV2StakedAmount) ||
                uniV2WithdrawAmount === "0" ? (
                <button className='uniV2-Staking-Withdraw-SelectBtn' disabled={true}>
                  INSUFFICIENT UNI-V2 BALANCE
                </button>
              ) : (
                <button className='uniV2-Staking-Withdraw-CanBtn' onClick={setUsdcRakis6UnStake}>
                  UNSTAKE
                </button>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className='uniV2-Staking-Deposit-LoadingContainer'>
          <Loading />
        </div>
      )}
    </>
  );
};

export default React.memo(UniV2WithdrawSection);
