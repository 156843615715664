import axios from "axios";

function SPRV2ResultViewAct(account) {
  return async (dispatch) => {
    try {
      if (account !== "") {
        // contract.post("/sprV2RewardView", sprV2Controller.SPR_V2_REWARD_VIEW); 이걸로 변경함

        // 내가 스테이킹한 정보 출력 함수 (스테이킹한 토큰 아이디 배열, 총 보상 받은 양, 클래임 받지 않은 보상 양, 스테이킹한 토큰 개수, 마지막 업데이트 시간)

        // const getStakerDataApi = await axios.post(`http://localhost:4000/block/sprV2StakerData`, {

        // const SPRV2ResultValueApi = await axios.post(`http://localhost:4000/block/sprV2RewardView`, {
        const SPRV2ResultValueApi = await axios.post(`https://back.khans.io/block/sprV2RewardView`, {
          account,
        });
        const getStakerDataApi = await axios.post(`https://back.khans.io/block/sprV2StakerData`, {
          account,
        });

        // console.log("getStakerDataApi", getStakerDataApi.data.countStaked);

        let [SPRV2ResultValue, getSPRV2UnClaimedRewards, getSPRV2TotalReward, getSPRV2CountStaked] = await Promise.all([
          SPRV2ResultValueApi.data,
          getStakerDataApi.data.unclaimedRewards,
          getStakerDataApi.data.totalReward,
          getStakerDataApi.data.countStaked,
        ]);
        dispatch({
          type: "SPRV2_RESULT_VIEW",
          payload: {
            SPRV2ResultValue: SPRV2ResultValue,
            getSPRV2UnClaimedRewards: getSPRV2UnClaimedRewards,
            getSPRV2TotalReward: getSPRV2TotalReward,
            getSPRV2CountStaked: getSPRV2CountStaked,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export const SPRV2ResultViewAction = { SPRV2ResultViewAct };
