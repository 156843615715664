import axios from "axios";

function uniV2ApproveStateAct(account) {
  return async (dispatch) => {
    try {
      if (account) {
        const getUniV2ApproveState = await axios.post(`https://back.khans.io/block/uniV2Allowance`, {
          account,
        });
        const uniV2ApproveStateApi = getUniV2ApproveState.data;

        let [uniV2ApproveState] = await Promise.all([uniV2ApproveStateApi]);

        dispatch({
          type: "UNIV2_STAKING_APPORVE_STATE",
          payload: { uniV2ApproveState: uniV2ApproveState },
        });
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export const uniV2ApproveStateAction = { uniV2ApproveStateAct };
