import axios from "axios";

export function hanStakingTokenListViewAct(account) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`https://back.khans.io/block/hanBonusStakerArray`, {
        // const response = await axios.post(`http://localhost:4000/block/hanBonusStakerArray`, {
        // account: "0x363754b91E7a473947581D8dbdd6d232f4484360",
        account,
      });

      // console.log("response", response);

      dispatch({
        type: "HAN_CHAIN_WITHDRAW_TOKEN_LIST",
        payload: {
          getHanStakerDataArray: response.data,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export const hanStakingTokenListViewAction = { hanStakingTokenListViewAct };
