import React from "react";
import "./Rakis49PoolTopSection.scss";
import { MainArrakisLogo } from "../../assets/_index";

const Rakis49PoolTopSection = () => {
  return (
    <div className="rakis49PoolTopSection">
      <img className="rakis49PoolLogo" src={MainArrakisLogo} alt="Arrakis6Logo" />
      <a>Add Liquidity</a>
    </div>
  );
};

export default Rakis49PoolTopSection;
