import { combineReducers } from "redux";
import stakingViewReducer from "./stakingViewReducer";
import rakis6StakingResultReducer from "./rakis6StakingResultReducer";
import accountReducer from "./accountReducer";
import globalContractViewReducer from "./globalContractViewReducer";
import SPRV2StakingResultReducer from "./SPRV2StakingReducer/SPRV2StakingResultReducer";
import SPRV2StakingViewReducer from "./SPRV2StakingReducer/SPRV2StakingViewReducer";
import gasPriceResultReducer from "./gasPriceResultReducer";
import airDropReducer from "./airDropReducer/wethAirDrop/airDropReducer";
import airDropLatestPriceReducer from "./airDropReducer/wethAirDrop/airDropLatestPriceReducer";
import coinPriceReducer from "./coinPriceReducer";
import networksReducer from "./networksReducer";
import musiKhanL1TokenApproveReducer from "./musiKhanReducer/L1/musiKhanL1TokenApproveReducer";
import musiKhanL1TokenBalanceReducer from "./musiKhanReducer/L1/musiKhanL1TokenBalanceReducer";
import musiKhanL1ViewReducer from "./musiKhanReducer/L1/musiKhanL1ViewReducer";
import L2BridgeViewReducer from "./musiKhanReducer/L2/L2BridgeViewReducer";
import L2BridgeMintReducer from "./musiKhanReducer/L2/L2BridgeMintReducer";
import musikhanCaViewReducer from "./musiKhanReducer/L2/musikhanCaViewReducer";
import musikhanL2ViewReducer from "./musiKhanReducer/L2/musikhanL2ViewReducer";
import musiKhanL2TokenApproveReducer from "./musiKhanReducer/L2/musiKhanL2TokenApproveReducer";
import musikhanL2RewardReducer from "./musiKhanReducer/L2/musikhanL2RewardReducer";
import musiAirDropReducer from "./airDropReducer/musiAirDropReducer/musiAirDropReducer";
import L2SwapViewReducer from "./musiKhanReducer/L2/L2SwapViewReducer";
import L2SwapTokenBalanceReducer from "./musiKhanReducer/L2/L2SwapTokenBalanceReducer";
import L2SwapTokenApproveReducer from "./musiKhanReducer/L2/L2SwapTokenApproveReducer";
import rakis6AirDropViewReducer from "./airDropReducer/rakis6AirDrop/rakis6AirDropViewReducer";
import rakis6AirDropTimeStampReducer from "./airDropReducer/rakis6AirDrop/rakis6AirDropTimeStampReducer";
import rakis6AirDropRewardReducer from "./airDropReducer/rakis6AirDrop/rakis6AirDropRewardReducer";
import rakis6AirDropTotalRewardReducer from "./airDropReducer/rakis6AirDrop/rakis6AirDropTotalRewardReducer";
import signUpReducer from "./airDropReducer/login/signUpReducer";
import hanAirDropViewReducer from "./airDropReducer/hanAirDrop/hanAirDropViewReducer";
import emailEffectReducer from "./airDropReducer/login/emailEffectReducer";
import munieV2StakingViewReducer from "./munieStakingReducer/munieStakingViewReducer";
import munieStakingResultReducer from "./munieStakingReducer/munieStakingResultReducer";
import munieAirDropViewReducer from "./airDropReducer/munieAirDrop/munieAirDropViewReducer";
import hanStakingViewReducer from "./hanStakingReducer/hanStakingViewReducer";
import hanStakingDurationReducer from "./hanStakingReducer/hanStakingDurationReducer";
import uniV2ViewReducer from "./uniV2ViewReducer/uniV2ViewReducer";
import UniV2PrivateViewReducer from "./airDropReducer/UniV2Private/UniV2PrivateViewReducer";
import UniV2PrRemainingDurationReducer from "./airDropReducer/UniV2Private/UniV2PrRemainingDurationReducer";
import usdcAirDropViewReducer from "./airDropReducer/UsdcAirDrop/usdcAirDropViewReducer";
import oldMunieStakingResultReducer from "./OldEplatReducer/OldMunieReducer/oldMunieStakingResultReducer";
import oldMunieStakingViewReducer from "./OldEplatReducer/OldMunieReducer/oldMunieStakingViewReducer";
// old Spr
import oldSprAllStakedViewReducer from "./OldEplatReducer/OldSprReducer/oldSprAllStakedViewReducer";
import oldSprStakingApproveReducer from "./OldEplatReducer/OldSprReducer/oldSprStakingApproveReducer";
import oldSprStakingResultReducer from "./OldEplatReducer/OldSprReducer/oldSprStakingResultReducer";
import oldSprStakingViewReducer from "./OldEplatReducer/OldSprReducer/oldSprStakingViewReducer";
//tokenomic
import ethHanTokenomicReducer from "./tokenomicReducer/ethHanTokenomicReducer";
import opHanTokenomicReducer from "./tokenomicReducer/opHanTokenomicReducer";
import ethHanEpTokenomicReducer from "./tokenomicReducer/ethHanEpTokenomicReducer";
import opHanEpTokenomicReducer from "./tokenomicReducer/opHanEpTokenomicReducer";
// rakis49
import rakis49ViewReducer from "./rakis49Reducer/rakis49ViewReducer";
import rakis49ResultViewReducer from "./rakis49Reducer/rakis49ResultViewReducer";
// pvtrakis49
import pvtRakis49RewardReducer from "./airDropReducer/pvtRakis49/pvtRakis49RewardReducer";
import pvtRakis49TimeStampReducer from "./airDropReducer/pvtRakis49/pvtRakis49TimeStampReducer";
import pvtRakis49ViewReducer from "./airDropReducer/pvtRakis49/pvtRakis49ViewReducer";
import rakis49PoolViewReducer from "./rakis49PoolReducer/rakis49PoolViewReducer";
// HANePStaking
import hanEpViewReducer from "./airDropReducer/hanEpReducer/hanEpViewReducer";
import hanEpRewardViewReducer from "./airDropReducer/hanEpReducer/hanEpRewardViewReducer";
import hanEpTimeStampReducer from "./airDropReducer/hanEpReducer/hanEpTimeStampReducer";
import hanEpAirDropViewReducer from "./airDropReducer/hanEpAirDrop/hanEpAirDropViewReducer";
import rakis49PoolRemoveViewReducer from "./rakis49PoolReducer/rakis49PoolRemoveViewReducer";
// MusikhanV2
import musikhanV2ViewReducer from "./musiKhanV2Reducer/musikhanV2ViewReducer";
import hanBonusViewReducer from "./airDropReducer/hanBonusReducer/hanBonusViewReducer";
import wethADLiquidityLockedViewReducer from "./airDropLockedLiquidityReducer/wethLockedReducer/wethADLiquidityLockedViewReducer";
import wbtcADLiquidityLockedViewReducer from "./airDropLockedLiquidityReducer/wbtcLockedReducer/wbtcADLiquidityLockedViewReducer";
// hanBonusV2
import hanBonusV2ViewReducer from "./airDropReducer/hanBonusV2Reducer/hanBonusV2ViewReducer";
//hanEpBonusV2
import hanEpBonusV2ViewReducer from "./airDropReducer/hanEpBonusV2Reducer/hanEpBonusV2ViewReducer";
//hanEPBonusV3
import hanEpBonusV3ViewReducer from "./airDropReducer/hanEpBonusV3Reducer/hanEpBonusV3ViewReducer";
import hanBonusV3ViewReducer from "./airDropReducer/hanBonusV3Reducer/hanBonusV3ViewReducer";
//DashBoard
import dashBoardViewReducer from "./dashBoardReducer/dashBoardViewReducer";
//Launchpad
import launchpadLiquidityReward4Reducer from "./launchpadLiquidityRewardAddReducers/launchpadLiquidityReward4Reducer";
import launchpadLiquidityRewardAddViewReducer from "./launchpadLiquidityRewardAddReducers/launchpadLiquidityRewardAddViewReducer";
//LiquidityReward HAN
import liquidityReward1ViewReducer from "./OldEplatReducer/liquidityRewardReducer/liquidityReward1ViewReducer";
import liquidityReward2ViewReducer from "./OldEplatReducer/liquidityRewardReducer/liquidityReward2ViewReducer";
import liquidityReward3ViewReducer from "./OldEplatReducer/liquidityRewardReducer/liquidityReward3ViewReducer";
import liquidityReward4ViewReducer from "./OldEplatReducer/liquidityRewardReducer/liquidityReward4ViewReducer";
import liquidityRewardViewReducer from "./OldEplatReducer/liquidityRewardReducer/liquidityRewardViewReducer";
//LiquidityReward HANeP
import liquidityHANePViewReducer from "./OldEplatReducer/liquidityRewardHANePReducer/liquidityHANePViewReducer";
import liquidityHANeP1ViewReducer from "./OldEplatReducer/liquidityRewardHANePReducer/liquidityHANeP1ViewReducer";
import liquidityHANeP4ViewReducer from "./OldEplatReducer/liquidityRewardHANePReducer/liquidityHANeP4ViewReducer";
import dashBoardV2ViewReducer from "./dashBoardReducer/dashBoardV2ViewReducer";
import liquidityRewardHANePReducer from "./dashBoardReducer/liquidityRewardHANePReducer";
import liquidityRewardHANReducer from "./OldEplatReducer/liquidityRewardReducer/liquidityRewardHANReducer";

export default combineReducers({
  stakingView: stakingViewReducer,
  rakis6StakingResultView: rakis6StakingResultReducer,
  account: accountReducer,
  globalContractView: globalContractViewReducer,
  SPRV2StakingResult: SPRV2StakingResultReducer,
  SPRV2StakingView: SPRV2StakingViewReducer,
  gasPrice: gasPriceResultReducer,
  airDropView: airDropReducer,
  airDropLatestPrice: airDropLatestPriceReducer,
  coinPrice: coinPriceReducer,
  networks: networksReducer,
  musiL1Approve: musiKhanL1TokenApproveReducer,
  musiL1TokenBalance: musiKhanL1TokenBalanceReducer,
  musikhanL1View: musiKhanL1ViewReducer,
  L2BridgeView: L2BridgeViewReducer,
  L2BridgeMint: L2BridgeMintReducer,
  musikhanCaView: musikhanCaViewReducer,
  musikhanL2View: musikhanL2ViewReducer,
  musiL2Approve: musiKhanL2TokenApproveReducer,
  L2RewardView: musikhanL2RewardReducer,
  musiAirDropView: musiAirDropReducer,
  L2SwapView: L2SwapViewReducer,
  L2SwapTokenBalanceView: L2SwapTokenBalanceReducer,
  L2SwapApprove: L2SwapTokenApproveReducer,
  rakis6AirDropView: rakis6AirDropViewReducer,
  rakis6AirDropTimeStamp: rakis6AirDropTimeStampReducer,
  rakis6AirDropReward: rakis6AirDropRewardReducer,
  rakis6AirDropTotalRewardView: rakis6AirDropTotalRewardReducer,
  signUp: signUpReducer,
  hanAirDropView: hanAirDropViewReducer,
  signUpEmail: emailEffectReducer,
  munieV2StakingView: munieV2StakingViewReducer,
  munieStakingResultView: munieStakingResultReducer,
  munieAirDropView: munieAirDropViewReducer,
  hanStakingView: hanStakingViewReducer,
  hanStakingDuration: hanStakingDurationReducer,
  uniV2View: uniV2ViewReducer,
  usdcAirDropView: usdcAirDropViewReducer,
  //old Munie
  oldMunieStakingResult: oldMunieStakingResultReducer,
  oldMunieStakingView: oldMunieStakingViewReducer,
  // old Spr
  oldSprAllStakedView: oldSprAllStakedViewReducer,
  oldSprStakingApprove: oldSprStakingApproveReducer,
  oldSprStakingResult: oldSprStakingResultReducer,
  oldSprStakingView: oldSprStakingViewReducer,
  //UniV2Private
  UniV2PrivateView: UniV2PrivateViewReducer,
  UniV2PrRemainingDuration: UniV2PrRemainingDurationReducer,
  ethHanTokenomic: ethHanTokenomicReducer,
  opHanTokenomic: opHanTokenomicReducer,
  ethHanEpTokenomic: ethHanEpTokenomicReducer,
  opHanEpTokenomic: opHanEpTokenomicReducer,
  //Rakis49
  rakis49View: rakis49ViewReducer,
  rakis49ResultView: rakis49ResultViewReducer,
  //PvtRakis49
  pvtRakis49Reward: pvtRakis49RewardReducer,
  pvtRakis49TimeStamp: pvtRakis49TimeStampReducer,
  pvtRakis49View: pvtRakis49ViewReducer,
  rakis49PoolView: rakis49PoolViewReducer,
  rakis49PoolRemoveView: rakis49PoolRemoveViewReducer,
  //HANeP Staking
  hanEpView: hanEpViewReducer,
  hanEpRewardView: hanEpRewardViewReducer,
  hanEpTimeStamp: hanEpTimeStampReducer,
  hanEpAirDropView: hanEpAirDropViewReducer,
  // MusiKhan V2
  musikhanV2View: musikhanV2ViewReducer,
  hanBonusView: hanBonusViewReducer,
  wethADLiquidityLockedView: wethADLiquidityLockedViewReducer,
  wbtcADLiquidityLockedView: wbtcADLiquidityLockedViewReducer,
  //hanBonus V2
  hanBonusV2View: hanBonusV2ViewReducer,
  //hanBonus V3
  hanBonusV3View: hanBonusV3ViewReducer,
  //hanepBonus V2
  hanEpBonusV2View: hanEpBonusV2ViewReducer,
  //hanepBonus V3
  hanEpBonusV3View: hanEpBonusV3ViewReducer,
  //DashBoard
  dashBoardView: dashBoardViewReducer,
  //launchpad
  launchpadLiquidityReward4: launchpadLiquidityReward4Reducer,
  launchpadLiquidityRewardAddView: launchpadLiquidityRewardAddViewReducer,
  //liquidityRewardHAN
  liquidityReward1View: liquidityReward1ViewReducer,
  liquidityReward2View: liquidityReward2ViewReducer,
  liquidityReward3View: liquidityReward3ViewReducer,
  liquidityReward4View: liquidityReward4ViewReducer,
  liquidityRewardView: liquidityRewardViewReducer,
  liquidityHANePView: liquidityHANePViewReducer,
  liquidityHANeP4View: liquidityHANeP4ViewReducer,
  liquidityHANeP1View: liquidityHANeP1ViewReducer,
  dashBoardV2View: dashBoardV2ViewReducer,
  //liquidityRewardHANeP global
  liquidityRewardHANeP: liquidityRewardHANePReducer,
  //liquidityRewardHAN global
  liquidityRewardHAN: liquidityRewardHANReducer,
});
