let initialState = {
  hanEpClaimDayDate: "",
  hanEpClaimHoursDate: "",
  hanEpClaimMinDate: "",
  hanEpClaimSecDate: "",
};

function hanEpTimeStampReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "HANEP_REMAINING_DURATION_VIEW":
      return {
        ...state,
        hanEpClaimDayDate: payload.hanEpClaimDayDate,
        hanEpClaimHoursDate: payload.hanEpClaimHoursDate,
        hanEpClaimMinDate: payload.hanEpClaimMinDate,
        hanEpClaimSecDate: payload.hanEpClaimSecDate,
      };

    default:
      return { ...state };
  }
}

export default hanEpTimeStampReducer;
