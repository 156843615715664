let initialState = {
  pvtRakis49ClaimDayDate: "",
  pvtRakis49ClaimHoursDate: "",
  pvtRakis49ClaimMinDate: "",
  pvtRakis49ClaimSecDate: "",
};

function pvtRakis49TimeStampReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "PVT_RAKIS49_AIRDROP_TIMESTAMP":
      return {
        ...state,
        pvtRakis49ClaimDayDate: payload.pvtRakis49ClaimDayDate,
        pvtRakis49ClaimHoursDate: payload.pvtRakis49ClaimHoursDate,
        pvtRakis49ClaimMinDate: payload.pvtRakis49ClaimMinDate,
        pvtRakis49ClaimSecDate: payload.pvtRakis49ClaimSecDate,
      };
    default:
      return { ...state };
  }
}

export default pvtRakis49TimeStampReducer;
