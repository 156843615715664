import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./MainTitleSection.scss";
import { hanChainPriceActtion } from "../../redux/actions/mainActions/hanChainPriceAction";
import { HanLogo } from "../../assets/_index";
import HanPriceLoading from "../GloBal/HanPriceLoading";

const MainTitleSection = () => {
  const dispatch = useDispatch();
  const { hanChainPrice, hanChainPercentage } = useSelector((state) => state.coinPrice);

  useEffect(() => {
    dispatch(hanChainPriceActtion.hanChainPriceAct());
  }, [dispatch]);
  return (
    <>
      <div className='mainPageTopPaddingSection'></div>
      <div className='mainPageLogoTitleContainer'>
        <div className='mainPageLogoSection'>
          <img src={HanLogo} alt='HanLogo' />
        </div>
        <div className='mainPageTitleTxtSection'>
          <a className='mainPageTitleTxt'>KHAN's STAKING</a>
          <div className='hanChainPriceContainer'>
            <div className='hanChainPriceSection'>
              <div className='hanChainPricTextSection'>
                <div className='hanChainPriceTitleSection'>
                  <div>
                    <a
                      href='https://www.coingecko.com/ko/%EC%BD%94%EC%9D%B8/hanchain'
                      target='_blank'
                      rel='noreferrer noopener'
                    >
                      HanChain (HAN)
                    </a>
                  </div>
                  {!hanChainPrice ? (
                    <div>
                      <HanPriceLoading />
                    </div>
                  ) : (
                    <div>
                      <span className='hanChainPriceAmountSection'>{hanChainPrice}</span>
                      <span className='hanChainPriceUnitSection'>USD</span>
                      {hanChainPercentage > 0 ? (
                        <span className='hanChainPrice24hPercentageInSection'>({hanChainPercentage}%)</span>
                      ) : (
                        <span className='hanChainPrice24HPercentageDeSection'>({hanChainPercentage}%)</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='coingecko_section'>
              <a
                href='https://www.coingecko.com/ko/%EC%BD%94%EC%9D%B8/hanchain'
                target='_blank'
                rel='noreferrer noopener'
              >
                Powered by CoinGecko
              </a>
            </div>
          </div>
          <div className='airDropSection'>
            <a href='/hanep' target='_blank' className='airDropTxt1' rel='noreferrer noopener'>
              HAN e-Platform
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(MainTitleSection);
