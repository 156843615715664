let initialState = {
  getHanEpProofToBack: "",
  getHanEpAmountToBack: "",
  canHanEpClaim: false,
  hanEpClaimed: false,
  claimHanEpDayDate: "",
  claimHanEpHoursDate: "",
  claimHanEpMinDate: "",
};

function hanEpAirDropViewReducer(state = initialState, action) {
  let { type, payload } = action;
  switch (type) {
    case "HANEP_AIRDROP_VIEW":
      return {
        ...state,
        getHanEpProofToBack: payload.getHanEpProofToBack,
        getHanEpAmountToBack: payload.getHanEpAmountToBack,
        canHanEpClaim: payload.canHanEpClaim,
      };

    case "GET_HANEP_AIRDROP_CLAIMED":
      return {
        ...state,
        hanEpClaimed: payload.hanEpClaimed,
      };

    case "GET_HANEP_AIRDROP_SUCCESS_TIMESTAMP":
      return {
        ...state,
        claimHanEpDayDate: payload.claimHanEpDayDate,
        claimHanEpHoursDate: payload.claimHanEpHoursDate,
        claimHanEpMinDate: payload.claimHanEpMinDate,
      };
    default:
      return { ...state };
  }
}

export default hanEpAirDropViewReducer;
