import Swal from "sweetalert2";
import { HanChainAddress, HanChainABI, HanBonusStakingAddress } from "../../../config/StakingHanChain";
import { ethers } from "ethers";

function hanStakingApproveAct(account, hanChainStakingNum, walletProvider) {
  return async (dispatch) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum); // Web3Modal을 통해 선택된 지갑의 provider를 사용합니다.
      // console.log("provider", provider);
      const signer = provider.getSigner(); // 서명자(Signer)를 가져옵니다.
      // console.log(signer);
      const contract = new ethers.Contract(HanChainAddress, HanChainABI, signer); // 스마트 컨트랙트 인스턴스를 생성합니다.
      const approve = await contract.approve(HanBonusStakingAddress, hanChainStakingNum); // claimReward 함수를 호출합니다.
      await approve.wait(); // 트랜잭션이 마이닝될 때까지 기다립니다.

      Swal.fire({
        title: "Success",
        text: "Approve was successful!",
        icon: "success",

        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
      // .then((result) => {
      //   if (result.isConfirmed) {
      //     window.location.reload();
      //   }
      // });
      dispatch({
        type: "HAN_CHAIN_APPROVE_SUCCESS",
        payload: {
          successHanChainApprove: true,
        },
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Fail",
        text: "Approve was Fail!",
        icon: "error",

        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };
}

export const hanStakingApproveAction = { hanStakingApproveAct };
