import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./HanRewardSection.scss";
import { RefreshIcon } from "../../assets/_index";
import { hanStakingRewardViewAction } from "../../redux/actions/hanStakingActions/hanStakingRewardViewAction";
import { hanStakingClaimAction } from "../../redux/actions/hanStakingActions/hanStakingClaimAction";
import Loading from "../GloBal/Loading";
import useWalletProvider from "../../hooks/useWalletProvider";
import useChainId from "../../hooks/useChainId";

const HanRewardSection = () => {
  const dispatch = useDispatch();
  const walletProvider = useWalletProvider();
  const checkChainId = useChainId();
  const { account } = useSelector((state) => state.account);

  const { hanRewardPerSecondView, hanTotalRewardReleased } = useSelector((state) => state.hanStakingView);

  const changeHanStakingRewardState = () => {
    dispatch(hanStakingRewardViewAction.hanStakingRewardViewAct(account));
  };

  const setHanChainClaim = () => {
    dispatch(hanStakingClaimAction.hanStakingClaimAct(account, walletProvider));
  };

  useEffect(() => {
    if (account) {
      dispatch(hanStakingRewardViewAction.hanStakingRewardViewAct(account));
    }
  }, [account, dispatch]);
  return (
    <>
      {checkChainId === "0xa" ? (
        <>
          <div className='han-Staking-Reward-EstSection'>
            <p>
              Estimated Interest : {hanRewardPerSecondView}
              <img
                src={RefreshIcon}
                width='20px'
                height='20px'
                className='han-Staking-Reward-RefreshIcon'
                onClick={changeHanStakingRewardState}
                alt='refreshIcon'
              />
              HAN
            </p>
          </div>
          {/* <div className="han-Staking-Reward-AccSection">
                   <p>Accumulated Interest : {hanRewardPerSecondView} HAN</p>
               </div> */}
          <div className='han-Staking-Reward-InterSection'>
            <p>Rewarded Interest : {hanTotalRewardReleased} HAN </p>
          </div>

          {/* 리워드 안될때 상태 추가 */}
          {hanRewardPerSecondView === "0.0" ? (
            <div className='han-Staking-Rewards-ClaimBtnSection'>
              <button className='han-Staking-Reward-CantBtn' disabled={true}>
                NOTHING TO CLAIM
              </button>
            </div>
          ) : (
            <div className='han-Staking-Rewards-ClaimBtnSection'>
              <button className='han-Staking-Reward-CanBtn' onClick={setHanChainClaim}>
                CLAIM
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          <div className='han-Staking-Deposit-LoadingContainer'>
            <Loading />
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(HanRewardSection);
