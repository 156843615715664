import React from "react";
import MoonLoader from "react-spinners/BounceLoader";
import "./MainLoading.scss";
import { HanLogo } from "../../assets/_index";

const MainLoading = () => {
  return (
    <>
      <div className='loadingMainContainer'>
        <div className='mainLoadingPageLogoSection'>
          <img src={HanLogo} alt='hanLogo' />
          <div className='mainLoadingPageTitle'>
            <p>KHAN's STAKING</p>
            <p className='mainLoadingPageTitle2'>Please don't close the window!</p>
          </div>
        </div>

        <MoonLoader
          color='#00557a'
          //  color="#1299FF"
          //  color="#39A2DB"
          //  color="#0A1126"
          size={108}
        />

        <div className='loadingTxt2Section'>
          <p>Take it slow!</p>
        </div>
        <div className='loadingDesktopSection'>
          <div className='loadingTxt3Section'>
            <p>Our website may load slowly,</p>
          </div>
          <div className='loadingTxt4Section'>
            <p>but it's worth the wait for carefully curated information. </p>
          </div>
          <div className='loadingTxt5Section'>
            <p>We're working on improving the loading speed. </p>
          </div>
        </div>
        <div className='loadingMobileSection'>
          <div className='loadingTxt3Section'>
            <p>Our website may load slowly,</p>
          </div>
          <div className='loadingTxt3Section'>
            <p>but it's worth the wait for</p>
          </div>
          <div className='loadingTxt4Section'>
            <p> arefully curated information. </p>
          </div>

          <div className='loadingTxt5Section'>
            <p>We're working on improving </p>
          </div>
          <div className='loadingTxt5Section'>
            <p>the loading speed. </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLoading;
