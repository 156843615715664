import React from "react";
import "./HanStakingConnectWalletSection.scss";
import { OptimismRedLogo, StopSignIcon } from "../../assets/_index";
import useWebConnectWallet from "../../hooks/useWebConnectWallet";
import useNetworkSwitcher from "../../hooks/useNetworkSwitcher";
import useChainId from "../../hooks/useChainId";
import { useSelector } from "react-redux";

const HanStakingConnectWalletSection = () => {
  const handleConnectWallet = useWebConnectWallet();
  const { switchToOptimism } = useNetworkSwitcher();
  const checkChainId = useChainId();
  const { account } = useSelector((state) => state.account);

  return (
    <div>
      {account === "" ? (
        <div className='connectHanStakingMetaWalletSection'>
          <a className='social-button button--social-login button--google' href='#' rel='noreferrer noopener'>
            <img
              width='20px'
              height='20px'
              src='https://static.coingecko.com/s/metamask_fox-99d631a5c38b5b392fdb2edd238a525ba0657bc9ce045077c4bae090cfc5b90a.svg'
              className='social-icon fa fa-google'
              alt='MetamaskIcon'
            ></img>
            <p onClick={handleConnectWallet}>Connect Wallet</p>
          </a>
        </div>
      ) : checkChainId === "0xa" ? (
        <div className='connectHanStakingOptiComWalletSection'>
          <a className='social-button button--social-login button--google' href='#' rel='noreferrer noopener'>
            <img
              width='20px'
              height='20px'
              src={OptimismRedLogo}
              className='social-icon fa fa-google'
              alt='OptimismIcon'
            ></img>
            {account.substr(0, 6)}...{account.slice(-6)}
          </a>
        </div>
      ) : (
        <div className='cantConnectHanStakingWalletSection'>
          <p className='cantConnectAirDropTxt'>Please switch to Optimism</p>
          <a
            className='social-button button--social-login button--google'
            href='#'
            rel='noreferrer noopener'
            onClick={switchToOptimism}
          >
            <img src={StopSignIcon} className='social-icon fa fa-google' alt='stopIcon' />
            {account.substr(0, 6)}...{account.slice(-6)}
          </a>
        </div>
      )}
    </div>
  );
};

export default HanStakingConnectWalletSection;
