const initialState = {
  liquidityRewardRewardView: "",
  liquidityRewardHANePStakerData: [],
  liquidityRewardCalculateHANeP: "",
  liquidityRewardCalculateWBTC: "",
  totalLiquidity: "",
  totalHanepAmount: "",
  totalWbtcAmount: "",
  totalRewardReleased: "",
  unclaimedRewards: "",
  unLimitIndexToModal: "",
  unLimitLiquidityToModal: "",
  unLimitHANePAmountToModal: "",
  unLimitLockupPeriodToModal: "",
  getLiquidityRewardHANePApr: "",
};

const liquidityRewardHANePReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "GLOBAL_LIQUIDITY_REWARD_HANEP":
      return {
        ...state,
        liquidityRewardRewardView: payload.liquidityRewardRewardView,
      };
    case "GLOBAL_LIQUIDITY_REWARD_HANEP_STKAER_DATA":
      return {
        ...state,
        liquidityRewardHANePStakerData: payload.liquidityRewardHANePStakerData,
      };
    case "GLOBAL_LIQUIDITY_REWARD_HANEP_CALCULATE_DATA":
      return {
        ...state,
        liquidityRewardCalculateHANeP: payload.liquidityRewardCalculateHANeP,
        liquidityRewardCalculateWBTC: payload.liquidityRewardCalculateWBTC,
      };
    case "GLOBAL_LIQUIDITY_REWARD_HANEP_TOTAL_INFO":
      return {
        ...state,
        totalLiquidity: payload.totalLiquidity,
        totalHanepAmount: payload.totalHanepAmount,
        totalWbtcAmount: payload.totalWbtcAmount,
        totalRewardReleased: payload.totalRewardReleased,
        unclaimedRewards: payload.unclaimedRewards,
      };
    case "GLOBAL_LIQUIDITY_REWARD_HANEP_MODAL_TO_PAGE":
      return {
        ...state,
        unLimitIndexToModal: payload.unLimitIndexToModal,
        unLimitLiquidityToModal: payload.unLimitLiquidityToModal,
        unLimitHANePAmountToModal: payload.unLimitHANePAmountToModal,
        unLimitLockupPeriodToModal: payload.unLimitLockupPeriodToModal,
      };
    case "GET_LIQUIDITY_REWARD_HANEP_APR":
      return {
        ...state,
        getLiquidityRewardHANePApr: payload.getLiquidityRewardHANePApr,
      };

    default:
      return { ...state };
  }
};

export default liquidityRewardHANePReducer;
