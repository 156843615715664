import Swal from "sweetalert2";
import { SPRV2StakingAddress, SPRV2StakingABI } from "../../../config/new/StakingSPRV2Config";
import { ethers } from "ethers";

function SPRV2ClaimAct(account, walletProvider) {
  return async (dispatch) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum); // Web3Modal을 통해 선택된 지갑의 provider를 사용합니다.
      const signer = provider.getSigner(); // 서명자(Signer)를 가져옵니다.
      const contract = new ethers.Contract(SPRV2StakingAddress, SPRV2StakingABI, signer); // 스마트 컨트랙트 인스턴스를 생성합니다.

      const claimReward = await contract.claimReward(); // claimReward 함수를 호출합니다.
      await claimReward.wait(); // 트랜잭션이 마이닝될 때까지 기다립니다.

      Swal.fire({
        title: "Success",
        text: "Claim was successful!",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      dispatch({
        type: "SUCCESS_SPRV2_CLAIM",
        payload: { successMunieClaim: true },
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Fail",
        text: "Claim was Fail!",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  };
}

export const SPRV2ClaimAction = { SPRV2ClaimAct };
