let initialState = {
  getHANePLaunchpadStakerData: [],
  totalLiquidity: "",
  totalHanepAmount: "",
  totalWbtcAmount: "",
  totalRewardReleased: "",
  unclaimedRewards: "",
  HANePLaunchpadRewardView: "",
  resultEthAmount: "",
  formattedUsdcValue: "",
  unLimitIndexToModal: "",
  unLimitLiquidityToModal: "",
  unLimitHANePAmountToModal: "",
  unLimitLockupPeriodToModal: "",
  getHANePLaunchCalculateHANeP: "",
  getHanLaunchCalculateWBTC: "",
  hasAddedLiquidity: false,
};

function liquidityHANeP4ViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "HANEP_UNLIMIT_LAUNCH_VIEW":
      return {
        ...state,
        getHANePLaunchpadStakerData: payload.getHANePLaunchpadStakerData,
      };
    case "HANEP_UNLIMIT_TOTAL_LAUNCH_VIEW":
      return {
        ...state,
        totalLiquidity: payload.totalLiquidity,
        totalHanepAmount: payload.totalHanepAmount,
        totalWbtcAmount: payload.totalWbtcAmount,
        totalRewardReleased: payload.totalRewardReleased,
        unclaimedRewards: payload.unclaimedRewards,
      };
    case "HANEP_LAUNCH_UNLIMIT_REWARD_VIEW":
      return {
        ...state,
        HANePLaunchpadRewardView: payload.HANePLaunchpadRewardView,
      };

    case "HANEP_LAUNCH_UNLIMIT_ETH_AMOUNT":
      return {
        ...state,
        resultEthAmount: payload.resultEthAmount,
      };
    case "HANEP_LAUNCH_UNLIMIT_USDC_AMOUNT":
      return {
        ...state,
        formattedUsdcValue: payload.formattedUsdcValue,
      };
    case "HANEP_UNLIMIT_MODAL_TO_PAGE_VIEW":
      return {
        ...state,
        unLimitIndexToModal: payload.unLimitIndexToModal,
        unLimitLiquidityToModal: payload.unLimitLiquidityToModal,
        unLimitHANePAmountToModal: payload.unLimitHANePAmountToModal,
        unLimitLockupPeriodToModal: payload.unLimitLockupPeriodToModal,
      };
    case "HANEP_UNLIMIT_LAUNCH_CALCULATE_VIEW":
      return {
        ...state,
        getHANePLaunchCalculateHANeP: payload.getHANePLaunchCalculateHANeP,
        getHANePLaunchCalculateWBTC: payload.getHANePLaunchCalculateWBTC,
      };
    case "HANEP_UNLIMIT_LAUNCH_HAS_LIQUDITY_VIEW":
      return {
        ...state,
        hasAddedLiquidity: payload.hasAddedLiquidity,
      };

    default:
      return {
        ...state,
      };
  }
}

export default liquidityHANeP4ViewReducer;
