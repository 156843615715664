import React from "react";
import MoonLoader from "react-spinners/BounceLoader";
import "./HanPriceLoading.scss";

const HanPriceLoading = () => {
  return (
    <div className='priceLoadingContainer'>
      <MoonLoader
        color='#00557a'
        //  color="#1299FF"
        //  color="#39A2DB"
        //  color="#0A1126"
        size={40}
      />
    </div>
  );
};

export default HanPriceLoading;
