import React from "react";
import "./MainTokenomicsSection.scss";
import { HANePLogo20, HanLogo } from "../../assets/_index";

const MainTokenomicsSection = () => {
  return (
    <div className='tokenomics-Section'>
      <div className='hanTokenomicsSection'>
        <a href='/hanscan' target='_blank' rel='noreferrer noopener'>
          <img src={HanLogo} width='20px' height='20px' alt='HanLogo'></img>
          Tokenomics
        </a>
      </div>
      <div className='hanEpTokenomicsSection'>
        <a href='/hanepscan' target='_blank' rel='noreferrer noopener'>
          <img src={HANePLogo20} width='20px' height='20px' alt='HANepLogo'></img>
          Tokenomics
        </a>
      </div>
      <div className='legalDisclaimerSection'>
        <a href='/LegalDisclaimer' target='_blank' rel='noreferrer noopener'>
          Legal Disclaimer
        </a>
      </div>
    </div>
  );
};

export default React.memo(MainTokenomicsSection);
