let initialState = {
  hanBonusV3Allowance: "",
  hanBonusV3RewardView: "",
  hanchainBalance: "",
  totalDividendAmount: "",
  totalproviderDividendAmount: "",
  totalStakedAmount: "",
  totalRewardReleased: "",
  unclaimedRewards: "",
  getHanBonusV3TotalSupply: "",
  hanBonusV3AllData: {},
  hanBonusV3IndexToModal: "",
  hanBonusV3AmountToModal: "",
  hanBonusV3WithdrawTimeToModal: "",
  successHanBonusV3Approve: false,
};

function hanBonusV3ViewReducer(state = initialState, action) {
  let { type, payload } = action;
  switch (type) {
    case "GET_HANBONUS_V3_VIEW":
      return {
        ...state,
        hanBonusV3Allowance: payload.hanBonusV3Allowance,
      };

    case "GET_HANBONUS_V3_REWARD_VIEW":
      return {
        ...state,
        hanBonusV3RewardView: payload.hanBonusV3RewardView,
      };

    case "GET_HANBONUS_V3_TOTAL_STAKER_VIEW":
      return {
        ...state,
        hanchainBalance: payload.hanchainBalance,
        totalDividendAmount: payload.totalDividendAmount,
        totalproviderDividendAmount: payload.totalproviderDividendAmount,
        totalStakedAmount: payload.totalStakedAmount,
        totalRewardReleased: payload.totalRewardReleased,
        unclaimedRewards: payload.unclaimedRewards,
        getHanBonusV3TotalSupply: payload.getHanBonusV3TotalSupply,
      };
    case "GET_HANBONUS_V3_ALL_DATA_VIEW":
      return {
        ...state,
        hanBonusV3AllData: payload.hanBonusV3AllData,
      };
    case "HANBONUS_V3_MODAL_TO_PAGE_VIEW":
      return {
        ...state,
        hanBonusV3IndexToModal: payload.hanBonusV3IndexToModal,
        hanBonusV3AmountToModal: payload.hanBonusV3AmountToModal,
        hanBonusV3WithdrawTimeToModal: payload.hanBonusV3WithdrawTimeToModal,
      };

    case "HANBONUSV3__APPROVE_SUCCESS":
      return {
        ...state,
        successHanBonusV3Approve: payload.successHanBonusV3Approve,
      };

    default:
      return { ...state };
  }
}

export default hanBonusV3ViewReducer;
