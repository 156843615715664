const initialState = {
  rakis49PoolWethBalanceOf: "",
  rakis49PoolHANePBalanceOf: "",
  rakis49PoolWethAllowance: "",
  rakis49PoolHanepAllowance: "",
  successRakis49WethApprove: false,
  successRakis49HanePApprove: false,
  mintWethAmount: "",
  mintWethHANePAmount: "",
  mintHANePWethAmount: "",
  mintHANePAmount: "",
  mintMaxAmountWeth: "",
  mintMaxAmountHanep: "",
};

const rakis49PoolViewReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "GET_RAKIS49_POOL_WETH_BALANCE":
      return {
        ...state,
        rakis49PoolWethBalanceOf: payload.rakis49PoolWethBalanceOf,
      };
    case "GET_RAKIS49_POOL_HANEP_BALANCE":
      return {
        ...state,
        rakis49PoolHANePBalanceOf: payload.rakis49PoolHANePBalanceOf,
      };

    case "GET_RAKIS49_POOL_WETH_ALLOWANCE":
      return {
        ...state,
        rakis49PoolWethAllowance: payload.rakis49PoolWethAllowance,
      };
    case "GET_RAKIS49_POOL_HANEP_ALLOWANCE":
      return {
        ...state,
        rakis49PoolHanepAllowance: payload.rakis49PoolHanepAllowance,
      };
    case "RAKIS49_POOL_WETH_APPROVE_SUCCESS":
      return {
        ...state,
        successRakis49WethApprove: payload.successRakis49WethApprove,
      };
    case "RAKIS49_POOL_HANEP_APPROVE_SUCCESS":
      return {
        ...state,
        successRakis49HanePApprove: payload.successRakis49HanePApprove,
      };

    case "GET_RAKIS49_GET_MINT_WETH":
      return {
        ...state,
        mintWethAmount: payload.mintWethAmount,
        mintWethHANePAmount: payload.mintWethHANePAmount,
      };
    case "GET_RAKIS49_GET_MINT_HANeP":
      return {
        ...state,
        mintHANePWethAmount: payload.mintHANePWethAmount,
        mintHANePAmount: payload.mintHANePAmount,
      };

    case "GET_RAKIS49_GET_MINT_MAX":
      return {
        ...state,
        mintMaxAmountWeth: payload.mintMaxAmountWeth,
        mintMaxAmountHanep: payload.mintMaxAmountHanep,
      };

    default:
      return { ...state };
  }
};

export default rakis49PoolViewReducer;
