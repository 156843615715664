import { useDispatch } from "react-redux";
import { connectAccount } from "../redux/actions/connectAccount";

const useWebConnectWallet = () => {
  const dispatch = useDispatch();

  const setup = () => {
    dispatch(connectAccount.getAccount());
  };

  const handleConnectWallet = async () => {
    if (window.ethereum === undefined) {
      window.open("https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn");
    } else {
      setup();
    }
  };

  return handleConnectWallet;
};

export default useWebConnectWallet;
