import axios from "axios";

function hanChainPriceAct() {
  return async (disptach) => {
    try {
      // const coinCeckgoApi = await axios.get(`http://localhost:4000/data/coinCeckgoApi`);
      const coinCeckgoApi = await axios.get(`https://back.khans.io/data/coinCeckgoApi`);

      let [hanChainPrice, hanChainPercentage] = await Promise.all([
        coinCeckgoApi.data.price,
        coinCeckgoApi.data.priceChangePercentage24h,
      ]);
      disptach({
        type: "GET_HANCHAIN_PRICE_SUCCESS",
        payload: {
          hanChainPrice: hanChainPrice.toFixed(4),
          hanChainPercentage: hanChainPercentage.toFixed(2),
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export const hanChainPriceActtion = { hanChainPriceAct };
