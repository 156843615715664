let initialState = {
  hanBonusV2Allowance: false,
  hanBonusV2RewardAmount: "",
  hanBonusV2RewardView: "",
  totalRewardReleased: "",
  totalStakedAmount: "",
  totalReferrerRewardAmount: "",
  unclaimedRewards: "",
  getHanBonusV2TotalSupply: "",
  hanBonusV2AllData: {},
  hanBonusV2IndexToModal: "",
  hanBonusV2AmountToModal: "",
  hanBonusV2WithdrawTimeToModal: "",
};

function hanBonusV2ViewReducer(state = initialState, action) {
  let { type, payload } = action;
  switch (type) {
    case "GET_HANBONUS_V2_REFERRER_VIEW":
      return {
        ...state,
        hanBonusV2Allowance: payload.hanBonusV2Allowance,
        hanBonusV2RewardAmount: payload.hanBonusV2RewardAmount,
      };

    case "GET_HANBONUS_V2_REWARD_VIEW":
      return {
        ...state,
        hanBonusV2RewardView: payload.hanBonusV2RewardView,
      };

    case "GET_HANBONUS_V2_TOTAL_REFERRER_VIEW":
      return {
        ...state,
        totalRewardReleased: payload.totalRewardReleased,
        totalStakedAmount: payload.totalStakedAmount,
        totalReferrerRewardAmount: payload.totalReferrerRewardAmount,
        unclaimedRewards: payload.unclaimedRewards,
        getHanBonusV2TotalSupply: payload.getHanBonusV2TotalSupply,
      };
    case "GET_HANBONUS_V2_ALL_DATA_VIEW":
      return {
        ...state,
        hanBonusV2AllData: payload.hanBonusV2AllData,
      };
    case "HANBONUS_V2_MODAL_TO_PAGE_VIEW":
      return {
        ...state,
        hanBonusV2IndexToModal: payload.hanBonusV2IndexToModal,
        hanBonusV2AmountToModal: payload.hanBonusV2AmountToModal,
        hanBonusV2WithdrawTimeToModal: payload.hanBonusV2WithdrawTimeToModal,
      };

    default:
      return { ...state };
  }
}

export default hanBonusV2ViewReducer;
