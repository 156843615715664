import React from "react";
import "./SprConnectWalletSection.scss";
import { StopSignIcon } from "../../assets/_index";
import useNetworkSwitcher from "../../hooks/useNetworkSwitcher";
import useWebConnectWallet from "../../hooks/useWebConnectWallet";
import useChainId from "../../hooks/useChainId";
import { useSelector } from "react-redux";

const SprConnectWalletSection = () => {
  const handleConnectWallet = useWebConnectWallet();
  const { switchToMainnet } = useNetworkSwitcher();
  const checkChainId = useChainId();
  const { account } = useSelector((state) => state.account);

  return (
    <div>
      {account === "" ? (
        <div className='connectSprWalletSection'>
          <a className='social-button button--social-login button--google' href='#' rel='noreferrer noopener'>
            <img
              width='20px'
              height='20px'
              src='https://static.coingecko.com/s/metamask_fox-99d631a5c38b5b392fdb2edd238a525ba0657bc9ce045077c4bae090cfc5b90a.svg'
              className='social-icon fa fa-google'
              alt='MetamaskIcon'
            ></img>
            <p onClick={handleConnectWallet}>Connect Wallet</p>
          </a>
        </div>
      ) : checkChainId === "0x1" ? (
        <div className='connectSprComWalletSection'>
          <a className='social-button button--social-login button--google' href='#' rel='noreferrer noopener'>
            <img
              width='20px'
              height='20px'
              src='https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880'
              className='social-icon fa fa-google'
              alt='EthereumIcon'
            ></img>
            {account.substr(0, 6)}...{account.slice(-6)}
          </a>
        </div>
      ) : (
        <div className='cantConnectSprWalletSection'>
          <p className='cantConnetSprTxt'>Please swith to mainnet</p>
          <a
            className='social-button button--social-login button--google'
            href='#'
            onClick={switchToMainnet}
            rel='noreferrer noopener'
          >
            <img src={StopSignIcon} className='social-icon fa fa-google' alt='stopIcon' />
            {account.substr(0, 6)}...{account.slice(-6)}
          </a>
        </div>
      )}
    </div>
  );
};

export default SprConnectWalletSection;
