let initialState = {
  getRakis49RewardReleased: "",
  getRakis49Balance: "",
  rakis49RewardResult: "",
};

function rakis49ResultViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "GET_RAKIS6EP_REWARD_VIEW":
      return {
        ...state,
        getRakis49RewardReleased: payload.getRakis49RewardReleased,
        getRakis49Balance: payload.getRakis49Balance,
        rakis49RewardResult: payload.rakis49RewardResult,
      };

    default:
      return { ...state };
  }
}

export default rakis49ResultViewReducer;
