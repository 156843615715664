import axios from "axios";

export function hanStakingRemainingDurationAct(account, index) {
  return async (dispatch) => {
    try {
      // console.log("index", index);
      // const response = await axios.post(`http://localhost:4000/block/hanBonusRemainingDuration`, {
      const response = await axios.post(`https://back.khans.io/block/hanBonusRemainingDuration`, {
        account,
        // account: "0x363754b91E7a473947581D8dbdd6d232f4484360",
        index,
      });

      const [hanClaimDayDate, hanClaimHoursDate, hanClaimMinDate, hanClaimSecDate] = response.data;

      dispatch({
        type: "HANCHAIN_DURATION_TIMESTAMP",
        payload: {
          hanClaimDayDate,
          hanClaimHoursDate,
          hanClaimMinDate,
          hanClaimSecDate,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export const hanStakingRemainingDurationAction = { hanStakingRemainingDurationAct };
