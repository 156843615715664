import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SprRewardSection.scss";
import { SPRV2ClaimAction } from "../../redux/actions/SPRV2StakingActions/SPRV2ClaimAction";
import { SPRV2ResultViewAction } from "../../redux/actions/SPRV2StakingActions/SPRV2ResultViewAction";
import { RefreshIcon } from "../../assets/_index";
import Loading from "../GloBal/Loading";
import useChainId from "../../hooks/useChainId";
import useWalletProvider from "../../hooks/useWalletProvider";

const SprRewardSection = () => {
  const dispatch = useDispatch();
  const checkChainId = useChainId();
  const walletProvider = useWalletProvider();
  const { account } = useSelector((state) => state.account);

  const { SPRV2ResultValue, getSPRV2UnClaimedRewards, getSPRV2TotalReward } = useSelector(
    (state) => state.SPRV2StakingResult
  );
  const { sprV2StakedAmount } = useSelector((state) => state.SPRV2StakingView);

  const sprClaim = () => {
    dispatch(SPRV2ClaimAction.SPRV2ClaimAct(account, walletProvider));
  };

  const changeSprState = () => {
    dispatch(SPRV2ResultViewAction.SPRV2ResultViewAct(account));
  };

  useEffect(() => {
    dispatch(SPRV2ResultViewAction.SPRV2ResultViewAct(account));
  }, [account, dispatch]);

  return (
    <div>
      {checkChainId === "0x1" ? (
        <>
          <div className='stakedSprCanAmountSection'>
            <p>STAKED : {sprV2StakedAmount} </p>
          </div>
          <div className='allRewardsSPRV2CumulativeSection'>
            <p>
              Estimated Interest : {SPRV2ResultValue}
              <img
                src={RefreshIcon}
                width='20px'
                height='20px'
                className='allRefreshSprClaimIcon'
                onClick={changeSprState}
                alt='refreshIcon'
              />
              HANeP
            </p>
          </div>
          <div className='amountTokenSprRewardAccSection'>
            <p>Accumulated Interest : {getSPRV2UnClaimedRewards} HANeP</p>
          </div>
          <div className='amountTokenRewardSprTxtSection'>
            <p>Rewarded Interest : {getSPRV2TotalReward} HANeP </p>
          </div>
          <div className='rewardsClaimSprBtnSection'>
            {SPRV2ResultValue === "0.0" && getSPRV2UnClaimedRewards === "0.0" ? (
              <button className='cant-spr-learn-more' disabled={true}>
                NOTHING TO CLAIM
              </button>
            ) : (
              <button className='spr-learn-more' onClick={sprClaim}>
                CLAIM
              </button>
            )}
          </div>
        </>
      ) : (
        <div className='sprStakingDepositContainer'>
          <div className='sprStakingCantChoiceImgContainer'>
            <Loading />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(SprRewardSection);
