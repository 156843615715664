const initialState = {
  liquidityRewardRewardView: "",
  liquidityRewardHANStakerData: [],
  liquidityRewardCalculateHAN: "",
  liquidityRewardCalculateWBTC: "",
  totalLiquidity: "",
  totalHanAmount: "",
  totalWbtcAmount: "",
  totalRewardReleased: "",
  unclaimedRewards: "",
  unLimitIndexToModal: "",
  unLimitLiquidityToModal: "",
  unLimitHANAmountToModal: "",
  unLimitLockupPeriodToModal: "",
  getLiquidityRewardHANApr: "",
};

const liquidityRewardHANReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "GLOBAL_LIQUIDITY_REWARD_VIEW_HAN":
      return {
        ...state,
        liquidityRewardRewardView: payload.liquidityRewardRewardView,
      };
    case "GLOBAL_LIQUIDITY_REWARD_HAN_STKAER_DATA":
      return {
        ...state,
        liquidityRewardHANStakerData: payload.liquidityRewardHANStakerData,
      };
    case "GLOBAL_LIQUIDITY_REWARD_HAN_CALCULATE_DATA":
      return {
        ...state,
        liquidityRewardCalculateHAN: payload.liquidityRewardCalculateHAN,
        liquidityRewardCalculateWBTC: payload.liquidityRewardCalculateWBTC,
      };
    case "GLOBAL_LIQUIDITY_REWARD_HAN_TOTAL_INFO":
      return {
        ...state,
        totalLiquidity: payload.totalLiquidity,
        totalHanAmount: payload.totalHanAmount,
        totalWbtcAmount: payload.totalWbtcAmount,
        totalRewardReleased: payload.totalRewardReleased,
        unclaimedRewards: payload.unclaimedRewards,
      };
    case "GLOBAL_LIQUIDITY_REWARD_HAN_MODAL_TO_PAGE":
      return {
        ...state,
        unLimitIndexToModal: payload.unLimitIndexToModal,
        unLimitLiquidityToModal: payload.unLimitLiquidityToModal,
        unLimitHANAmountToModal: payload.unLimitHANAmountToModal,
        unLimitLockupPeriodToModal: payload.unLimitLockupPeriodToModal,
      };
    case "GET_LIQUIDITY_REWARD_HAN_APR":
      return {
        ...state,
        getLiquidityRewardHANApr: payload.getLiquidityRewardHANApr,
      };

    default:
      return { ...state };
  }
};

export default liquidityRewardHANReducer;
