import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./UniV2DepositSection.scss";
import { uniV2ViewAction } from "../../redux/actions/uniV2StakingActions/uniV2ViewAction";
import Loading from "../GloBal/Loading";
import { uniV2ApproveStateAction } from "../../redux/actions/uniV2StakingActions/uniV2ApproveStateAction";
import { uniV2StakeAction } from "../../redux/actions/uniV2StakingActions/uniV2StakeAction";
import { uniV2ApproveAction } from "../../redux/actions/uniV2StakingActions/uniV2ApproveAction";
import useChainId from "../../hooks/useChainId";
import useWalletProvider from "../../hooks/useWalletProvider";
import { ethers } from "ethers";

const UniV2DepositSection = () => {
  const dispatch = useDispatch();
  const checkChainId = useChainId();
  const walletProvider = useWalletProvider();
  const { account } = useSelector((state) => state.account);
  const [uniV2StakingAmount, setUniV2StakingAmount] = useState(0);

  const { uniV2StakingTokenBalance, successUniV2Approve, uniV2ApproveState } = useSelector((state) => state.uniV2View);

  const changeUniV2DepositAmount = (e) => {
    const pattern = /^(\d{0,4}([.]\d{0,18})?)?$/;
    if (pattern.test(e.target.value)) {
      setUniV2StakingAmount(e.target.value);
    }
  };

  const changeUniV2MaxDepositAmount = () => {
    setUniV2StakingAmount(uniV2StakingTokenBalance);
  };

  const setUniV2Approve = () => {
    let uniV2StakingAmount = document.getElementById("maxUniV2StakeAmount").value;
    const uniV2StakingNum = ethers.utils.parseEther(String(uniV2StakingAmount));
    dispatch(uniV2ApproveAction.uniV2ApproveAct(account, uniV2StakingNum, walletProvider));
  };

  const setUniV2Stake = () => {
    let uniV2StakingAmount = document.getElementById("maxUniV2StakeAmount").value;
    const uniV2StakingNum = ethers.utils.parseEther(String(uniV2StakingAmount));
    dispatch(uniV2StakeAction.uniV2StakeAct(account, uniV2StakingNum, walletProvider));
  };

  useEffect(() => {
    dispatch(uniV2ViewAction.uniV2ViewAct(account));
    dispatch(uniV2ApproveStateAction.uniV2ApproveStateAct(account));
  }, [account, dispatch]);

  return (
    <>
      {checkChainId === "0x1" ? (
        <>
          <div className='uniV2-Staking-Deposit-TokenBalanceSection'>
            <p>Available : {uniV2StakingTokenBalance} </p>
          </div>
          <div className='uniV2-Staking-Deposit-Approve-AmountSection'>
            <input
              type='number'
              step='0.000000000000000001'
              id='maxUniV2StakeAmount'
              placeholder='0'
              onChange={changeUniV2DepositAmount}
              value={uniV2StakingAmount}
            ></input>
            <p>UNI-V2</p>
            <button className='uniV2-Staking-Deposit-AmountMaxBtn' onClick={changeUniV2MaxDepositAmount}>
              Max
            </button>
          </div>
          {uniV2StakingAmount === "" || uniV2StakingAmount === 0 ? (
            <div className='uniV2-Staking-DepositStakeBtnSection'>
              <button className='uniV2-Staking-Deposit-CantBtn'>ENTER AMOUNT</button>
            </div>
          ) : uniV2StakingTokenBalance === "0.0" ||
            parseFloat(uniV2StakingAmount) > parseFloat(uniV2StakingTokenBalance) ||
            uniV2StakingAmount === "00" ? (
            <div className='uniV2-Staking-DepositStakeBtnSection'>
              <button className='uniV2-Staking-Deposit-CantBtn' disabled={true}>
                INSUFFICIENT UNI-V2 BALANCE
              </button>
            </div>
          ) : parseFloat(uniV2ApproveState) > parseFloat(uniV2StakingTokenBalance) ||
            parseFloat(uniV2ApproveState) === "0.0" ? (
            <div className='uniV2-Staking-DepositStakeBtnSection'>
              <button onClick={setUniV2Approve} className='uniV2-Staking-Deposit-CanBtn'>
                APPROVE
              </button>
            </div>
          ) : parseFloat(uniV2StakingAmount) <= parseFloat(uniV2ApproveState) ? (
            <div className='uniV2-Staking-DepositStakeBtnSection'>
              <button onClick={setUniV2Stake} className='uniV2-Staking-Deposit-CanBtn'>
                STAKE
              </button>
            </div>
          ) : successUniV2Approve === false ? (
            <div className='uniV2-Staking-DepositStakeBtnSection'>
              <button onClick={setUniV2Approve} className='uniV2-Staking-Deposit-CanBtn'>
                APPROVE
              </button>
            </div>
          ) : (
            <div className='uniV2-Staking-DepositStakeBtnSection'>
              <button onClick={setUniV2Stake} className='uniV2-Staking-Deposit-CanBtn'>
                STAKE
              </button>
            </div>
          )}
        </>
      ) : (
        <div className='uniV2-Staking-Deposit-LoadingContainer'>
          <Loading />
        </div>
      )}
    </>
  );
};

export default React.memo(UniV2DepositSection);
