import axios from "axios";

function uniV2APRViewAct(account) {
  return async (dispatch) => {
    try {
      if (account) {
        const getUniV2APR = await axios.get(`https://back.khans.io/block/uniV2AprView`);

        const stakingUniV2APRApi = getUniV2APR.data;

        let [stakingUniV2APR] = await Promise.all([stakingUniV2APRApi]);

        dispatch({
          type: "UNIV2_STAKING_APR",
          payload: {
            stakingUniV2APR: stakingUniV2APR,
          },
        });
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export const uniV2APRViewAction = { uniV2APRViewAct };
