let initialState = {
  getRakis49StakedAmount: "",
  getRakis49StakingTokenBalance: "",
  getRakis49Apr: "",
  getRakis49AmountStake: "",
  getRakis49AllowanceAmount: "",
  successRakis49Approve: false,
};

function rakis49ViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "GET_RAKIS49_VIEW":
      return {
        ...state,
        getRakis49StakedAmount: payload.getRakis49StakedAmount,
        getRakis49StakingTokenBalance: payload.getRakis49StakingTokenBalance,
        getRakis49Apr: payload.getRakis49Apr,
        getRakis49AmountStake: payload.getRakis49AmountStake,
      };
    case "GET_RAKIS6EP_APPROVE_STATE":
      return {
        ...state,
        getRakis49AllowanceAmount: payload.getRakis49AllowanceAmount,
      };
    case "SUCCESS_RAKIS49_APPORVE":
      return {
        ...state,
        successRakis49Approve: payload.successRakis49Approve,
      };

    default:
      return { ...state };
  }
}

export default rakis49ViewReducer;
