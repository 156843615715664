let initialState = {
  hanChainLaunchpad1HasAdded: false,
  hanChainLaunchpad1EthAmount: "",
  hanChainLaunchpad2HasAdded: false,
  hanChainLaunchpad2EthAmount: "",
};

function launchpadLiquidityRewardAddViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "LAUNCHPAD_LIQUIDITY_REWARD1_ETH_AMOUNT":
      return {
        ...state,
        hanChainLaunchpad1HasAdded: payload.hanChainLaunchpad1HasAdded,
      };
    case "LAUNCHPAD_LIQUIDITY_SELECT_REWARD1_ETH_AMOUNT":
      return {
        ...state,
        hanChainLaunchpad1EthAmount: payload.hanChainLaunchpad1EthAmount,
      };
    case "LAUNCHPAD_LIQUIDITY_REWARD2_ETH_AMOUNT":
      return {
        ...state,
        hanChainLaunchpad2HasAdded: payload.hanChainLaunchpad2HasAdded,
      };
    case "LAUNCHPAD_LIQUIDITY_SELECT_REWARD2_ETH_AMOUNT":
      return {
        ...state,
        hanChainLaunchpad2EthAmount: payload.hanChainLaunchpad2EthAmount,
      };

    default:
      return { ...state };
  }
}

export default launchpadLiquidityRewardAddViewReducer;
