import axios from "axios";

function SPRV2SingleApporveStateAct(tokenId, account) {
  return async (dispatch) => {
    try {
      if (account !== "") {
        // contract.post("/sprV2ApprovedState", sprV2Controller.SPR_V2_GET_APPROVED); 이걸로 변경했음 매개변수 tokenId로 변경
        const getSPRV2SingleApprovedApi = await axios.post(`https://back.khans.io/block/sprV2ApprovedState`, {
          tokenId,
        });

        let [getSPRV2SingleApproved] = await Promise.all([getSPRV2SingleApprovedApi.data]);

        // 유효성 검사를 수행하여 잘못된 BigNumber 문자열 값인지 확인
        dispatch({
          type: "GET_SPRV2_SINGLE_APPROVE_STATE_VIEW",
          payload: {
            getSPRV2SingleApproved: getSPRV2SingleApproved,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "ERROR_OCCURRED",
        payload: {
          error: error.message,
        },
      });
    }
  };
}

export const SPRV2SingleApporveStateAction = { SPRV2SingleApporveStateAct };
