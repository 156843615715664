let initialState = {
  pvtRakis49UnClaimedRewardToEth: "",
  pvtRakis49TotalRewardReceivedToEth: "",
  pvtRakis49TotalRewardAmount: "",
  getPvtRakis49TotalRewardView: "",
};

function pvtRakis49RewardReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "GET_RAKIS49_REWARD_VIEW":
      return {
        ...state,
        pvtRakis49UnClaimedRewardToEth: payload.pvtRakis49UnClaimedRewardToEth,
        pvtRakis49TotalRewardReceivedToEth: payload.pvtRakis49TotalRewardReceivedToEth,
        pvtRakis49TotalRewardAmount: payload.pvtRakis49TotalRewardAmount,
      };

    case "GET_RAKIS49_TOTAL_REWARD_VIEW":
      return {
        ...state,
        getPvtRakis49TotalRewardView: payload.getPvtRakis49TotalRewardView,
      };
    default:
      return { ...state };
  }
}

export default pvtRakis49RewardReducer;
