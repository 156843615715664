import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { signUpAction } from "../../redux/actions/airdropActions/signUpActions/signUpAction";
import { connectAccount } from "../../redux/actions/connectAccount";
import Swal from "sweetalert2";
import Captcha from "./Login/Captcha";
import validator from "validator";
import "./AirDropSignEmail.scss";

const OldHanEpSignEmail = () => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState("");
  const [inputCode, setInputCode] = useState(0);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isTimeExpired, setIsTimeExpired] = useState(false);
  const [canSendEmail, setCanSendEmail] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [showCheckIdModal, setShowCheckIdModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);
  const [timerStarted, setTimerStarted] = useState(false);
  const { duplicate, code } = useSelector((state) => state.signUpEmail);
  const { account } = useSelector((state) => state.account);

  const date = new Date(timeLeft * 1000);
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const handleEmailChange = (e) => {
    setAddress(e.target.value);
  };

  const handleCodeChange = (e) => {
    setInputCode(Number(e.target.value));
  };

  const openCheckIdModal = () => {
    setShowCheckIdModal(true);
  };

  const closeCheckIdModal = () => {
    setShowCheckIdModal(false);
  };

  const checkDuplicate = () => {
    if (validator.isEmail(address)) {
      //Check Email
      if (duplicate && address !== "") {
        Swal.fire({
          title: "Address available",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
          html: `<h3 style='color:red'>Click "get code"</h3>`,
        });
        setCanSendEmail(true);
      } else {
        Swal.fire({
          text: "Duplicate email or wallet address",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        setCanSendEmail(false);
      }
    } else {
      Swal.fire({
        //Check Email
        text: "Email format is incorrect",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };

  const sendCode = () => {
    if (timerStarted) {
      Swal.fire({
        text: "We have already sent you a verification code.",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } else {
      if (canSendEmail) {
        dispatch(signUpAction.sendCodeAct(address));
        startTimer();
        setIsTimeExpired(false);
        setIsCodeSent(true);
        setTimerStarted(true);

        Swal.fire({
          text: "Please check the verification code in your email",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          text: "Please check email duplicates first.",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const startTimer = () => {
    const timer = setInterval(() => {
      setTimeLeft((time) => time - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      setIsTimeExpired(true);
      setTimerStarted(false);
      setTimeLeft(180);
    }, 180001);
  };

  const verifyCode = () => {
    if (code === inputCode && isCodeSent && inputCode !== "") {
      setIsCodeValid(true);
      Swal.fire({
        text: "Verification code is correct",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } else {
      setIsCodeValid(false);
      Swal.fire({
        text: "Verification code is incorrect",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };

  const handleCodeValidation = () => {
    if (!isTimeExpired) {
      verifyCode();
    } else {
      Swal.fire({
        text: "Time has passed. Please send the request again",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };

  const handleSignUp = () => {
    if (isCodeValid) {
      dispatch(signUpAction.signUpAct(address, account));
      dispatch(signUpAction.nextComPage());
    } else {
      Swal.fire({
        text: "Please check your verification code",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    dispatch(connectAccount.getAccount());
    dispatch(signUpAction.emailDuplicateCheckAct(address, account));
  }, [dispatch, address, account]);

  return (
    <div className='airDrop-SignUp-Wrap-Step2'>
      <div className='airDrop-SignUp-Wrap-Step2-Section'>
        <div className='airDrop-SignUp-Email-Enter-Section'>
          {isCodeSent ? (
            <div>
              <input
                onChange={handleEmailChange}
                value={address}
                type='email'
                placeholder='Please enter your email.'
                disabled
                required
              ></input>
            </div>
          ) : (
            <div>
              <input
                onChange={handleEmailChange}
                value={address}
                type='email'
                placeholder='Please enter your email.'
                required
              ></input>
            </div>
          )}
          {!canSendEmail ? (
            <button onClick={checkDuplicate}>Check</button>
          ) : (
            <button onClick={sendCode}>Get Code</button>
          )}
        </div>
        <div className='airDrop-SignUp-Code-Check-Container'>
          {!isCodeValid ? (
            <div className='airDrop-SignUp-Code-Check-Section'>
              <input onChange={handleCodeChange} placeholder='Please enter your verification code.'></input>
              <button onClick={handleCodeValidation}>Submit</button>
            </div>
          ) : (
            <div className='airDrop-SignUp-Code-Check-Section'>
              <input disabled placeholder='Code.'></input>
              <button disabled>Complete</button>
            </div>
          )}
          <div className='airDrop-SignUp-Email-Timer-Section'>
            {!isCodeValid ? (
              <p>
                {minutes} : {seconds}
              </p>
            ) : (
              <p>success</p>
            )}
          </div>
        </div>

        <div className='airDrop-SignUp-Metamask-Account-Section'>
          <p>Address : {account} </p>
          <div className='airDrop-SignUp-CheckId-Section'>
            <a onClick={openCheckIdModal}>Forgot your account ?</a>
          </div>
        </div>

        <div className='airDrop-SignUp-Email-CompelteBtn-Section'>
          <button onClick={handleSignUp}>Sign up</button>
        </div>

        <Captcha open={showCheckIdModal} address={address} close={closeCheckIdModal} header='Modal heading'></Captcha>
      </div>
    </div>
  );
};

export default OldHanEpSignEmail;
