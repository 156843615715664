import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./UniV2RewardSection.scss";
import { uniV2RewardVIewAction } from "../../redux/actions/uniV2StakingActions/uniV2RewardVIewAction";
import { uniV2ClaimAction } from "../../redux/actions/uniV2StakingActions/uniV2ClaimAction";
import { RefreshIcon } from "../../assets/_index";
import Loading from "../GloBal/Loading";
import useWalletProvider from "../../hooks/useWalletProvider";
import useChainId from "../../hooks/useChainId";

const UniV2RewardSection = () => {
  const dispatch = useDispatch();
  const walletProvider = useWalletProvider();
  const checkChainId = useChainId();
  const { account } = useSelector((state) => state.account);

  const { uniV2StakedrewardReleased, uniV2StakedunclaimedReward, uniV2RewardView } = useSelector(
    (state) => state.uniV2View
  );

  const changeUsdcRakis6RewardState = () => {
    dispatch(uniV2RewardVIewAction.uniV2RewardVIewAct(account));
  };

  const setUsdcClaim = () => {
    dispatch(uniV2ClaimAction.uniV2ClaimAct(account, walletProvider));
  };

  useEffect(() => {
    dispatch(uniV2RewardVIewAction.uniV2RewardVIewAct(account));
  }, [account, dispatch]);

  return (
    <>
      {checkChainId === "0x1" ? (
        <>
          <div className='uniV2-Staking-Reward-EstSection'>
            <p>
              Estimated Interest : {uniV2RewardView}
              <img
                src={RefreshIcon}
                width='20px'
                height='20px'
                className='uniV2-Staking-Reward-RefreshIcon'
                onClick={changeUsdcRakis6RewardState}
                alt='refreshIcon'
              />
              HANeP
            </p>
          </div>
          <div className='uniV2-Staking-Reward-AccSection'>
            <p>Accumulated Interest : {uniV2StakedunclaimedReward} HANeP</p>
          </div>
          <div className='uniV2-Staking-Reward-InterSection'>
            <p>Rewarded Interest : {uniV2StakedrewardReleased} HANeP </p>
          </div>

          {/* 리워드 안될때 상태 추가 */}

          <div className='uniV2-Staking-Rewards-ClaimBtnSection'>
            {uniV2RewardView + uniV2StakedunclaimedReward <= 0 ? (
              <button className='uniV2-Staking-Reward-CantBtn' disabled={true}>
                NOTHING TO CLAIM
              </button>
            ) : (
              <button className='uniV2-Staking-Reward-CanBtn' onClick={setUsdcClaim}>
                CLAIM
              </button>
            )}
          </div>
        </>
      ) : (
        <div className='uniV2-Staking-Deposit-LoadingContainer'>
          <Loading />
        </div>
      )}
    </>
  );
};

export default React.memo(UniV2RewardSection);
