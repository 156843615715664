import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../GloBal/Loading";
import "./SprViewAdsSection.scss";
import { allSPRV2StakedViewAction } from "../../redux/actions/SPRV2StakingActions/allSPRV2StakedViewAction";
import { DownArrowIcon, UpArrowIcon } from "../../assets/_index";

const SprViewAdsSection = () => {
  const dispatch = useDispatch();
  const ref = useRef();

  const { getSPRV2StakingTokenIdImgVideoUrl } = useSelector((state) => state.SPRV2StakingView);

  const goUp = (id) => {
    id.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const goDown = (id) => {
    id.scrollTo({
      top: id.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  const stopScroll = (id) => {
    id.scrollTop = id.scrollTop;
  };

  useEffect(() => {
    dispatch(allSPRV2StakedViewAction.allSPRV2StakedViewAct());
  }, [dispatch]);

  return (
    <div>
      {getSPRV2StakingTokenIdImgVideoUrl.length === 0 ? (
        // <div className="sprStakingWithdrawContainer">
        //   <div className="sprStakingCantChoiceContainer">
        //     <div className="sprStakingCantChoiceSection">
        //       <a className="cantStakingSprBtn" disabled={true}>
        //         INSUFFICIENT BALANCE
        //       </a>
        //     </div>
        //   </div>
        // </div>
        <div className='sprStakingCantViewContainer'>
          <Loading />
        </div>
      ) : (
        <div className='sprAllStakingComContainer'>
          <div className='sprAllStakingArrowUpSection'>
            <img
              src={UpArrowIcon}
              onPointerDown={(e) => goUp(ref.current)}
              onPointerUp={(e) => stopScroll(ref.current)}
              alt='upArrow'
            />
          </div>
          <div className='sprAllStakingContainer'>
            {getSPRV2StakingTokenIdImgVideoUrl !== "" ? (
              <div className='allStakingInfoImgContainer'>
                <div className='scrollBox'>
                  <div className='scrollBoxInner' ref={ref}>
                    {getSPRV2StakingTokenIdImgVideoUrl.map((item, index) => {
                      return (
                        <div className='allStakingInfoSection' key={index}>
                          <div
                            className='allStakingInfoImgSection'
                            style={{
                              backgroundImage: `url(https://gateway.pinata.cloud/ipfs/${item.image})`,
                            }}
                            onClick={() => window.open(`${item.externalUrl}`, "_blank")}
                          ></div>
                          <div className='allStakingInfoNameSection'>
                            <p>{item.name}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className='sprAllStakingArrowDownSection'>
            <img
              src={DownArrowIcon}
              onPointerDown={(e) => goDown(ref.current)}
              onPointerUp={(e) => stopScroll(ref.current)}
              alt='downArrow'
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(SprViewAdsSection);
