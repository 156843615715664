const initialState = {
  V2EnabledTokenList: [],
  getV2TokenAddress: "",
  getV2TokenSymbol: "",
  musiV2Period: "",
  musiV2StakedTokenList: [],
  v2TotalRewardReleased: "",
  v2TokenRewardView: "",
  v2RewardTokenCa: "",
  v2RewardSymbol: "",
  v2RewardStakedAmount: "",
  v2AmountRewarded: "",
  v2StaedTokenState: null,
};

const musikhanV2ViewReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "V2_ENABLED_TOKENLIST_TOBACK":
      return {
        ...state,
        V2EnabledTokenList: payload.V2EnabledTokenList,
      };
    case "V2_VIEW_SUCCESS":
      return {
        ...state,
        getV2TokenAddress: payload.getV2TokenAddress,
        getV2TokenSymbol: payload.getV2TokenSymbol,
      };
    case "V2_PERIOD_VIEW":
      return {
        ...state,
        musiV2Period: payload.musiV2Period,
      };
    case "V2_STAKED_TOKENLIST_TOBACK":
      return {
        ...state,
        musiV2StakedTokenList: payload.musiV2StakedTokenList,
      };
    case "V2_TOTAL_REWARD_RELEASED_TOBACK":
      return {
        ...state,
        v2TotalRewardReleased: payload.v2TotalRewardReleased,
      };
    case "V2_TOKEN_REWARD_VIEW":
      return {
        ...state,
        v2TokenRewardView: payload.v2TokenRewardView,
        v2RewardTokenCa: payload.v2RewardTokenCa,
      };
    case "V2_REWARD_TOKEN_SYMBOL":
      return {
        ...state,
        v2RewardSymbol: payload.v2RewardSymbol,
        v2RewardStakedAmount: payload.v2RewardStakedAmount,
      };
    case "V2_REWARD_STAKER_INFO":
      return {
        ...state,
        v2AmountRewarded: payload.v2AmountRewarded,
      };
    case "V2_STAKED_TOKEN_STATE":
      return {
        ...state,
        v2StaedTokenState: payload.v2StaedTokenState,
      };

    default:
      return { ...state };
  }
};

export default musikhanV2ViewReducer;
