let initialState = {
  hanEpTokenQuota: "",
  hanEpTotalSupply: "",
  hanEpWhitelistAmount: "",
  hanepApr: "",
  hanEpStakerArray: "",
  hanEpWithdrawAmountToModal: "",
  hanEpWithdrawIndex: "",
  hanEpHanRewardAmountToModal: "",
  successHanepApporve: false,
};

function hanEpViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "GET_HANEP_VIEW":
      return {
        ...state,
        hanEpTokenQuota: payload.hanEpTokenQuota,
        hanEpTotalSupply: payload.hanEpTotalSupply,
        hanEpWhitelistAmount: payload.hanEpWhitelistAmount,
      };

    case "GET_HANEP_APR_VIEW":
      return {
        ...state,
        hanepApr: payload.hanepApr,
      };

    case "GET_HANEP_WITHDRAW_LIST_VIEW":
      return {
        ...state,
        hanEpStakerArray: payload.hanEpStakerArray,
      };

    case "GET_HANEP_WITHDRAW_VIEW":
      return {
        ...state,
        hanEpWithdrawAmountToModal: payload.hanEpWithdrawAmountToModal,
        hanEpWithdrawIndex: payload.hanEpWithdrawIndex,
        hanEpHanRewardAmountToModal: payload.hanEpHanRewardAmountToModal,
      };
    case "PVT_HANEP_APPROVE_SUCCESS":
      return {
        ...state,
        successHanepApporve: payload.successHanepApporve,
      };

    // case "GET_HANEP_APR_VIEW":
    //   return {
    //     ...state,
    //     HanQuantityLpQuantityPerYear1HanValue: payload.HanQuantityLpQuantityPerYear1HanValue,
    //   };

    default:
      return { ...state };
  }
}

export default hanEpViewReducer;
