let initialState = {
  hanLPWBTCBalance: "",
  hanAmount: "",
  wbtcAmount: "",
  hanLPTotalReward: "",
  hanLPUnClaimedRewards: "",
  lockupPeriod: 0,
  hasAddedLiquidity: false,
  hanChainLaunchpadUSDPrice: "",
  getPooledHan: "",
  hanChainLaunchpadRewardView: "",
  getHanLaunchCalculateHan: "",
  getHanLaunchCalculateWBTC: "",
};

function liquidityReward1ViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "HANCHAIN_750_LAUNCH_VIEW":
      return {
        ...state,
        hanLPWBTCBalance: payload.hanLPWBTCBalance,
        hanAmount: payload.hanAmount,
        wbtcAmount: payload.wbtcAmount,
        hanLPTotalReward: payload.hanLPTotalReward,
        hanLPUnClaimedRewards: payload.hanLPUnClaimedRewards,
        lockupPeriod: payload.lockupPeriod,
        hasAddedLiquidity: payload.hasAddedLiquidity,
        hanChainLaunchpadUSDPrice: payload.hanChainLaunchpadUSDPrice,
        getPooledHan: payload.getPooledHan,
      };

    case "HANCHAIN_LAUNCH_750_REWARD_VIEW":
      return {
        ...state,
        hanChainLaunchpadRewardView: payload.hanChainLaunchpadRewardView,
      };

    case "HANCHAIN_750_LAUNCH_CALCULATE_VIEW":
      return {
        ...state,
        getHanLaunchCalculateHan: payload.getHanLaunchCalculateHan,
        getHanLaunchCalculateWBTC: payload.getHanLaunchCalculateWBTC,
      };

    default:
      return {
        ...state,
      };
  }
}

export default liquidityReward1ViewReducer;
