let initialState = {
  resultEthAmount: "",
  formattedUsdcValue: "",
  getPooledHan: "",
};

function launchpadLiquidityReward4Reducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "LAUNCHPAD_LIQUIDITY_REWARD4_ETH_AMOUNT":
      return {
        ...state,
        resultEthAmount: payload.resultEthAmount,
      };
    case "LAUNCHPAD_LIQUIDITY_REWARD4_USDC_AMOUNT":
      return {
        ...state,
        formattedUsdcValue: payload.formattedUsdcValue,
      };
    case "LAUNCHPAD_POOLED_HAN":
      return {
        ...state,
        getPooledHan: payload.getPooledHan,
      };

    default:
      return { ...state };
  }
}

export default launchpadLiquidityReward4Reducer;
