import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SPRV2StakingAddress } from "../../config/new/StakingSPRV2Config";
import Carousel from "react-multi-carousel";
import Loading from "../GloBal/Loading";
import "./SprDepositSection.scss";
import { SPRV2StakeAction } from "../../redux/actions/SPRV2StakingActions/SPRV2StakeAction";
import { SPRV2SingleApproveAction } from "../../redux/actions/SPRV2StakingActions/SPRV2SingleApproveAction";
import { SPRV2SingleApporveStateAction } from "../../redux/actions/SPRV2StakingActions/SPRV2SingleApporveStateAction";
import { SPRV2DepositListAction } from "../../redux/actions/SPRV2StakingActions/SPRV2DepositListAction";
import { LeftArrowIcon, RightArrowIcon } from "../../assets/_index";
import useChainId from "../../hooks/useChainId";
import { SPRV2StakingViewAction } from "../../redux/actions/SPRV2StakingActions/SPRV2StakingViewAction";
import useWalletProvider from "../../hooks/useWalletProvider";

const SprDepositSection = () => {
  const dispatch = useDispatch();
  const walletProvider = useWalletProvider();
  const checkChainId = useChainId();
  const [stakingmyTokenId, setMyTokenId] = useState(1);
  const { account } = useSelector((state) => state.account);

  const { getMySPRTokenDepositList, getSPRV2SingleApproved, successSPRV2Approve, sprV2StakedAmount } = useSelector(
    (state) => state.SPRV2StakingView
  );

  const sprStaking = () => {
    dispatch(SPRV2StakeAction.SPRV2StakeAct(Number(stakingmyTokenId), account, walletProvider));
  };

  const sprSingleApprove = () => {
    dispatch(SPRV2SingleApproveAction.SPRV2SingleApproveAct(Number(stakingmyTokenId), account, walletProvider));
  };

  const handleClickButton = (myTokenId) => {
    dispatch({ type: "SELECT_SPRV2_STAKING_NFT", payload: myTokenId });
  };

  const stakingCheckOnlyOne = (checkThis) => {
    const checkboxes = document.getElementsByName("test3");
    // console.log(checkboxes)
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i] !== checkThis) {
        checkboxes[i].checked = false;
      } else {
        setMyTokenId(checkThis.value);
      }
    }
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      // partialVisibilityGutter: 96,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className='carousel-button-group'>
        <div className='sliderRightBtn'>
          <img src={RightArrowIcon} id='sliderRightBtn' onClick={() => next()} alt='rightArrowIcon' />
        </div>
        <div className='sliderLeftBtn'>
          <img
            src={LeftArrowIcon}
            id='sliderLeftBtn'
            className={currentSlide === 0 ? "disable" : ""}
            onClick={() => previous()}
            alt='leftArrowIcon'
          />
        </div>
        {/* <ButtonThree onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </ButtonThree> */}
      </div>
    );
  };

  useEffect(() => {
    dispatch(SPRV2DepositListAction.SPRV2DepositListAct(account));
    dispatch(SPRV2StakingViewAction.SPRV2StakingViewAct(account));
  }, [account, dispatch]);

  useEffect(() => {
    dispatch(SPRV2SingleApporveStateAction.SPRV2SingleApporveStateAct(Number(stakingmyTokenId), account));
  }, [stakingmyTokenId, account, dispatch]);

  // console.log("getMySPRTokenDepositList", getMySPRTokenDepositList.data);

  return (
    <div>
      {checkChainId === "0x1" ? (
        <>
          <div className='stakedSprCanAmountSection'>
            <p>STAKED : {sprV2StakedAmount} </p>
          </div>
          {getMySPRTokenDepositList !== null ? (
            getMySPRTokenDepositList?.length === 0 ? (
              <div className='sprStakingDepositContainer'>
                <div className='sprStakingCantChoiceImgContainer'>
                  <div className='sprStakingCantChoiceImgSection'>
                    <b className='cantStakingSprBtn' disabled={true}>
                      INSUFFICIENT BALANCE
                    </b>
                  </div>
                </div>
                {/* <div className="sprStakingSelectBtnSection">
                       <button onClick={sprMint}>Test Minting</button>
                       <button onClick={test}>Test Token</button>
                     </div> */}
              </div>
            ) : (
              <div className='sprStakingDepositContainer'>
                <div className='sprStakingBeforeChoiceImgContainer'>
                  {getMySPRTokenDepositList !== "" ? (
                    <Carousel
                      responsive={responsive}
                      arrows={false}
                      className='mainUnSlider'
                      partialVisible
                      customButtonGroup={<ButtonGroup />}
                      renderButtonGroupOutside
                    >
                      {getMySPRTokenDepositList?.map((item, index) => {
                        return (
                          <div className='sprStakingSlider' key={index}>
                            <div className='sprStakingImgContainer'>
                              <div
                                className='sprStakingImgCard'
                                style={{
                                  // backgroundImage: `url(https://gateway.pinata.cloud/ipfs/QmcTcBbZtNRbwnDSjGjwfYXt8SiWahPtMFSL77dgfzHPUX)`,
                                  backgroundImage: `url(https://gateway.pinata.cloud/ipfs/${item.image})`,
                                }}
                              >
                                <input
                                  className='imgCheckBox'
                                  name='test3'
                                  type='radio'
                                  key={index}
                                  value={item.tokenId}
                                  onClick={() => handleClickButton}
                                  onChange={(e) => stakingCheckOnlyOne(e.target)}
                                ></input>
                              </div>
                              <div className='sprStakingImgTokenId'>
                                <p>Sheepoori # {item.tokenId}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  ) : null}
                  {/* <div className='sprStakingSelectBtnSection'>
                                       <button onClick={sprMint}>
                                         Test Minting
                                       </button>
                                       <button onClick={test}>
                                         Test Token
                                       </button>
                         </div> */}
                </div>
                <div className='depositSprStakeBtnSection'>
                  {getSPRV2SingleApproved === SPRV2StakingAddress ? (
                    <button className='spr-learn-more' onClick={sprStaking}>
                      STAKE
                    </button>
                  ) : successSPRV2Approve === false ? (
                    <button className='spr-learn-more' onClick={sprSingleApprove}>
                      APPROVE
                    </button>
                  ) : (
                    <button className='spr-learn-more' onClick={sprStaking}>
                      STAKE
                    </button>
                  )}
                </div>
              </div>
            )
          ) : (
            <div className='sprStakingDepositContainer'>
              <div className='sprStakingCantChoiceImgContainer'>
                <Loading />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='sprStakingDepositContainer'>
          <div className='sprStakingCantChoiceImgContainer'>
            <Loading />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(SprDepositSection);
