import React, { useEffect, useState } from "react";
import "./MainPage.scss";
import {
  MainFooterSection,
  MainMarketSection,
  MainStakingSection,
  MainTitleSection,
  MainTokenomicsSection,
} from "../components";
import KhansTermsOfServiceModal from "../components/GloBal/KhansTermsOfServiceModal";
// import MainTermsFooterModalSection from "../components/MainPage/MainTermsFooterModalSection";

const MainPage = () => {
  const [isDisclaimerAccepted, setIsDisclaimerAccepted] = useState(false);

  useEffect(() => {
    // localStorage에서 disclaimerAccepted 값 확인
    const accepted = sessionStorage.getItem("disclaimerAccepted");
    if (accepted) {
      setIsDisclaimerAccepted(true);
    }
  }, []);

  const handleAcceptDisclaimer = () => {
    setIsDisclaimerAccepted(true);
    sessionStorage.setItem("disclaimerAccepted", "true"); // 사용자가 disclaimer를 수락하면 localStorage에 저장
  };
  return (
    <>
      {!isDisclaimerAccepted && <KhansTermsOfServiceModal onClose={handleAcceptDisclaimer} />}
      <div className='mainPageTestContainer'>
        <MainTitleSection />
        <div className='mainPageStakingPoolContainer'>
          <MainStakingSection />
          <MainMarketSection />
        </div>
        <div className='home-footer-logo'>
          <MainTokenomicsSection />
          {/* <MainTermsFooterModalSection /> */}
          <MainFooterSection />
        </div>
      </div>
    </>
  );
};

export default MainPage;
