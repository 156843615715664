let initialState = {
  optimisticEthBalance: "",
};

function globalContractViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "OPTIMISTIC_ETH_BALANCE":
      return {
        ...state,
        optimisticEthBalance: payload.optimisticEthBalance,
      };

    default:
      return { ...state };
  }
}

export default globalContractViewReducer;
