import React from "react";
import "./MainFooterSection.scss";
import {
  MainAdLogo,
  MainDiscordLogo,
  MainEnterLogo,
  MainFacebookLogo,
  MainGithubLogo,
  MainMediumLogo,
  MainOffLogo,
  MainTelegramLogo,
  MainTwitterLogo,
} from "../../assets/_index";

const MainFooterSection = () => {
  const myFunction = () => {
    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
  };

  const outFunc = () => {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy to clipboard";
  };

  const addRewardToken = async () => {
    const tokenAddress = "0xC7483FbDB5c03E785617a638E0f22a08da10084B";
    const tokenSymbol = "HAN";
    const tokenDecimals = 18;
    const tokenImage =
      "https://raw.githubusercontent.com/hanchain-paykhan/hanchain/3058eecc5d26f980db884f1318da6c4de18a7aea/logo/logo.svg";

    try {
      const wasAdded = await window.ethereum?.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className='home-footer'>
      <div className='ether'>
        <span className='ether_logo'>
          <img
            width='20px'
            height='20px'
            src='https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880'
            alt='EthereumIcon'
          />
        </span>
        <input
          className='ether_txt'
          id='myInput'
          defaultValue='0x0c90c57aaf95a3a87eadda6ec3974c99d786511f'
          style={{ border: "none", background: "transparent" }}
        />
        <a className='ether_copy tooltip'>
          <button
            onClick={myFunction}
            onMouseOut={outFunc}
            style={{
              border: "none",
              background: "transparent",
              display: "flex",
            }}
          >
            <span className='tooltiptext' id='myTooltip'>
              Copy to clipboard
            </span>
            <i className='far fa-far fa-clone' />
          </button>
        </a>
        <a onClick={addRewardToken} className='tooltip'>
          <img
            width='20px'
            height='20px'
            src='https://static.coingecko.com/s/metamask_fox-99d631a5c38b5b392fdb2edd238a525ba0657bc9ce045077c4bae090cfc5b90a.svg'
            alt='MetamaskLogo'
          />
          <span className='tooltiptext' id='metamask_txt'>
            Add to Metamask
          </span>
        </a>
      </div>
      <div className='logoimg'>
        <div className='logo_twiter'>
          <a href='https://twitter.com/HanIdentity' target='_blank' className='tooltip2' rel='noreferrer noopener'>
            <img src={MainTwitterLogo} alt='TwitterLogo' />
            <span className='tooltiptext2'>Twiter</span>
          </a>
        </div>
      </div>
      <div className='logoimg'>
        <div className='logo_facebook'>
          <a
            href='https://www.facebook.com/HanChainGlobalOfficial-101331419212206'
            target='_blank'
            className='tooltip2'
            rel='noreferrer noopener'
          >
            <img src={MainFacebookLogo} alt='FacebookLogo' />
            <span className='tooltiptext2'>Facebook</span>
          </a>
        </div>
      </div>
      <div className='logoimg'>
        <div className='logo_discord'>
          <a href='https://discord.gg/5gtfUuvJJX' target='_blank' className='tooltip2' rel='noreferrer noopener'>
            <img src={MainDiscordLogo} alt='DiscordLogo' />
            <span className='tooltiptext2'>Discord</span>
          </a>
        </div>
      </div>
      <div className='logoimg'>
        <div className='logo_telegram'>
          <a href='https://t.me/hanchain_official' target='_blank' className='tooltip2' rel='noreferrer noopener'>
            <img src={MainTelegramLogo} alt='TelegramLogo' />
            <span className='tooltiptext2'>Telegram</span>
          </a>
        </div>
      </div>
      <div className='logoimg'>
        <div className='logo_medium'>
          <a href='https://medium.com/@HanIdentity' target='_blank' className='tooltip2'>
            <img src={MainMediumLogo} alt='MediumLogo' />
            <span className='tooltiptext2'>Medium</span>
          </a>
        </div>
      </div>
      <div className='logoimg'>
        <div className='logo_github'>
          <a href='https://github.com/hanchain-paykhan' target='_blank' className='tooltip2' rel='noreferrer noopener'>
            <img src={MainGithubLogo} alt='GithubLogo' />
            <span className='tooltiptext2'>Github</span>
          </a>
        </div>
      </div>
      <div className='logoimg'>
        <div className='logo_enter'>
          <a
            href='https://www.youtube.com/channel/UCQPzdwU4KHlXO3srolte0Dg'
            target='_blank'
            className='tooltip2'
            rel='noreferrer noopener'
          >
            <img src={MainEnterLogo} alt='EnterLogo' />
            <span className='tooltiptext2'>Entertainment</span>
          </a>
        </div>
      </div>
      <div className='logoimg'>
        <div className='logo_office'>
          <a
            href='https://www.youtube.com/channel/UCw_N38K7yK754M7wbaOpx0g'
            target='_blank'
            className='tooltip2'
            rel='noreferrer noopener'
          >
            <img src={MainOffLogo} alt='MainOffLogo' />
            <span className='tooltiptext2'>Youtube</span>
          </a>
        </div>
      </div>
      <div className='logoimg'>
        <div className='logo_ad'>
          <a
            href='https://www.youtube.com/channel/UCekUY9Bc3J9adN2tQ-uDXqA'
            target='_blank'
            className='tooltip2'
            rel='noreferrer noopener'
          >
            <img src={MainAdLogo} alt='MainAdLogo' />
            <span className='tooltiptext2'>Advertisement</span>
          </a>
        </div>
      </div>
    </div>
  );
};
export default React.memo(MainFooterSection);
