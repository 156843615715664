import axios from "axios";

function uniV2ViewAct(account) {
  return async (dispatch) => {
    try {
      if (account) {
        const getUniV2StakingTokenBalance = await axios.post(`https://back.khans.io/block/uniV2BalanceOf`, {
          account,
        });

        const uniV2StakingTokenBalanceApi = getUniV2StakingTokenBalance.data;

        const getUniV2StakerApi = await axios.post(`https://back.khans.io/block/uniV2Stakers`, {
          account,
        });

        const uniV2StakedAmountApi = getUniV2StakerApi.data[0];
        const uniV2StakedrewardReleasedApi = getUniV2StakerApi.data[1];
        const uniV2StakedunclaimedRewardApi = getUniV2StakerApi.data[2];

        // 컨트랙트에 유저들이 스테이킹한 토큰 양
        const getUniV2TotalSupply = await axios.get(`https://back.khans.io/block/uniV2TotalSupply`);

        const uniV2TotalSupplyApi = getUniV2TotalSupply.data;

        let [uniV2StakingTokenBalance, uniV2StakedAmount, uniV2StakedrewardReleased, uniV2StakedunclaimedReward, uniV2TotalSupply] =
          await Promise.all([
            uniV2StakingTokenBalanceApi,
            uniV2StakedAmountApi,
            uniV2StakedrewardReleasedApi,
            uniV2StakedunclaimedRewardApi,
            uniV2TotalSupplyApi,
          ]);

        dispatch({
          type: "UNIV2_STAKING_VIEW_SUCCESS",
          payload: {
            uniV2StakingTokenBalance: uniV2StakingTokenBalance,
            uniV2StakedAmount: uniV2StakedAmount,
            uniV2StakedrewardReleased: uniV2StakedrewardReleased,
            uniV2StakedunclaimedReward: uniV2StakedunclaimedReward,
            uniV2TotalSupply: uniV2TotalSupply,
          },
        });
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export const uniV2ViewAction = { uniV2ViewAct };
