import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "./SprWithdrawSection.scss";
import { SPRV2UnstakeAction } from "../../redux/actions/SPRV2StakingActions/SPRV2UnstakeAction";
import { SPRV2WithdrawListAction } from "../../redux/actions/SPRV2StakingActions/SPRV2WithdrawListAction";
import { LeftArrowIcon, RightArrowIcon } from "../../assets/_index";
import Loading from "../GloBal/Loading";
import useWalletProvider from "../../hooks/useWalletProvider";
import useChainId from "../../hooks/useChainId";

const SprWithdrawSection = () => {
  const dispatch = useDispatch();
  const walletProvider = useWalletProvider();
  const checkChainId = useChainId();
  const [mySPRV2StakedTokenId, setMySPRV2StakedTokenId] = useState("");

  const { account } = useSelector((state) => state.account);

  const { stakingTokenIdImgBack, sprV2WithdrawListBack, sprV2StakedAmount } = useSelector(
    (state) => state.SPRV2StakingView
  );

  const sprUnStaking = () => {
    dispatch(SPRV2UnstakeAction.SPRV2UnstakeAct(Number(mySPRV2StakedTokenId), account, walletProvider));
  };

  const selectUnStakingCheckButton = (myStakedTokenId) => {
    // console.log(myStakedTokenId, "체크");
    dispatch({ type: "SELECT_SPRV2_UNSTAKING_NFT", payload: myStakedTokenId });
  };

  const checkOnlyOne = (checkThis) => {
    const checkboxes = document.getElementsByName("test2");
    // console.log(checkboxes)
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i] !== checkThis) {
        checkboxes[i].checked = false;
      } else {
        setMySPRV2StakedTokenId(checkThis.value);
        // console.log(checkThis.value);
      }
    }
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      // partialVisibilityGutter: 96,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className='carousel-button-group'>
        <div className='sliderRightBtn'>
          <img src={RightArrowIcon} id='sliderRightBtn' onClick={() => next()} alt='rightArrow' />
        </div>
        <div className='sliderLeftBtn'>
          <img
            src={LeftArrowIcon}
            id='sliderLeftBtn'
            alt='leftarrow'
            className={currentSlide === 0 ? "disable" : ""}
            onClick={() => previous()}
          />
        </div>
        {/* <ButtonThree onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </ButtonThree> */}
      </div>
    );
  };

  useEffect(() => {
    dispatch(SPRV2WithdrawListAction.SPRV2WithdrawListAct(account));
  }, [account, dispatch]);

  return (
    <>
      {checkChainId === "0x1" ? (
        <>
          <div className='stakedSprCanAmountSection'>
            <p>STAKED : {sprV2StakedAmount} </p>
          </div>
          {sprV2WithdrawListBack.length === 0 ? (
            <div className='sprStakingWithdrawContainer'>
              <div className='sprStakingCantChoiceContainer'>
                <div className='sprStakingCantChoiceSection'>
                  <b className='cantStakingSprBtn' disabled={true}>
                    INSUFFICIENT BALANCE
                  </b>
                </div>
              </div>
            </div>
          ) : (
            <div className='sprStakingWithdrawContainer'>
              <div className='sprUnStakingChoiceImgContainer'>
                {stakingTokenIdImgBack !== "" ? (
                  <Carousel
                    responsive={responsive}
                    arrows={false}
                    className='mainUnSlider'
                    partialVisible
                    customButtonGroup={<ButtonGroup />}
                    renderButtonGroupOutside
                  >
                    {stakingTokenIdImgBack.map((item, index) => {
                      return (
                        <div className='sprUnStakingSlider' key={index}>
                          <div className='sprUnStakingImgContainer' id='sprUnStakingImgIdCardContainer'>
                            <div
                              className='sprUnStakingImgCard'
                              style={{
                                // backgroundImage: `url(https://gateway.pinata.cloud/ipfs/QmcTcBbZtNRbwnDSjGjwfYXt8SiWahPtMFSL77dgfzHPUX)`,
                                backgroundImage: `url(https://gateway.pinata.cloud/ipfs/${item.image})`,
                              }}
                            >
                              <input
                                className='imgUnCheckBox'
                                name='test2'
                                type='radio'
                                key={index}
                                value={item.tokenId}
                                onClick={() => selectUnStakingCheckButton(item.tokenId)}
                                onChange={(e) => checkOnlyOne(e.target)}
                              />
                            </div>
                            <div className='sprStakingImgTokenId'>
                              <p>Sheepoori # {item.tokenId}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                ) : null}
              </div>
              <div className='sprUnStakeBtnSection'>
                <button className='spr-learn-more' onClick={sprUnStaking}>
                  UNSTAKE
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='sprStakingDepositContainer'>
          <div className='sprStakingCantChoiceImgContainer'>
            <Loading />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(SprWithdrawSection);
