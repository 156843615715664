let initialState = {
  hanEpBonusV3Allowance: "",
  getHanEpBonusV3APR: "",
  hanepBonusV3RewardView: "",
  hanEpV3BalanceOf: "",
  totalDividendAmount: "",
  totalproviderDividendAmount: "",
  totalStakedAmount: "",
  totalRewardReleased: "",
  unclaimedRewards: "",
  getHanEpBonusV3TotalSupply: "",
  successHANePBonusV3Approve: false,
  hanEpBonusV3AllData: [],
  hanEpBonusV3IndexToModal: "",
  hanEpBonusV3AmountToModal: "",
  hanEpBonusV3WithdrawTimeToModal: "",
};

function hanEpBonusV3ViewReducer(state = initialState, action) {
  let { type, payload } = action;
  switch (type) {
    case "GET_HANEPBONUS_V3_VIEW":
      return {
        ...state,
        hanEpBonusV3Allowance: payload.hanEpBonusV3Allowance,
        getHanEpBonusV3APR: payload.getHanEpBonusV3APR,
      };
    case "GET_HANEPBONUS_V3_REWARD_VIEW":
      return {
        ...state,
        hanepBonusV3RewardView: payload.hanepBonusV3RewardView,
      };
    case "GET_HANEPBONUS_V3_TOTAL_REFERRER_VIEW":
      return {
        ...state,
        hanEpV3BalanceOf: payload.hanEpV3BalanceOf,
        totalDividendAmount: payload.totalDividendAmount,
        totalproviderDividendAmount: payload.totalproviderDividendAmount,
        totalStakedAmount: payload.totalStakedAmount,
        totalRewardReleased: payload.totalRewardReleased,
        unclaimedRewards: payload.unclaimedRewards,
        getHanEpBonusV3TotalSupply: payload.getHanEpBonusV3TotalSupply,
      };
    case "HANEP_BONUS_V3_APPROVE_SUCCESS":
      return {
        ...state,
        successHANePBonusV3Approve: payload.successHANePBonusV3Approve,
      };
    case "GET_HANEPBONUS_V3_ALL_DATA_VIEW":
      return {
        ...state,
        hanEpBonusV3AllData: payload.hanEpBonusV3AllData,
      };
    case "HANEPBONUS_V3_MODAL_TO_PAGE_VIEW":
      return {
        ...state,
        hanEpBonusV3IndexToModal: payload.hanEpBonusV3IndexToModal,
        hanEpBonusV3AmountToModal: payload.hanEpBonusV3AmountToModal,
        hanEpBonusV3WithdrawTimeToModal: payload.hanEpBonusV3WithdrawTimeToModal,
      };

    default:
      return { ...state };
  }
}

export default hanEpBonusV3ViewReducer;
