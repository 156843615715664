import axios from "axios";
function SPRV2DepositListAct(account) {
  return async (dispatch) => {
    try {
      if (account !== "") {
        // 주소별 가지고 있는 민팅된 tokenID
        // contract.post("/sprV2StakerData", sprV2Controller.SPR_V2_GET_STAKER_DATA); 이걸로 변경함 여기에 tokenIds가 스테이킹 된 토큰 아이디 배열임
        const getMySPRTokenDepositListApi = await axios.post(`https://back.khans.io/block/sprV2DepositList`, {
          account,
        });

        // console.log("getMySPRTokenDepositListApi", getMySPRTokenDepositListApi.data);

        let [getMySPRTokenDepositList] = await Promise.all([getMySPRTokenDepositListApi.data]);

        dispatch({
          type: "SPRV2_DEPOSIT_LIST",
          payload: {
            getMySPRTokenDepositList: getMySPRTokenDepositList,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export const SPRV2DepositListAction = { SPRV2DepositListAct };
