import { createWeb3Modal, defaultConfig, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";

const useWalletProvider = () => {
  const { walletProvider } = useWeb3ModalProvider();

  // console.log("walletProvider", walletProvider);
  // 1. Get projectId
  const projectId = "4569bda1ea5b27c685e374309f54164f";

  // 2. Set chains
  const mainnet = {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: "https://cloudflare-eth.com",
  };

  const optimism = {
    chainId: 10,
    name: "Optimism",
    currency: "ETH",
    explorerUrl: "https://optimistic.etherscan.io/",
    rpcUrl: "https://mainnet.optimism.io",
  };

  // 3. Create modal
  const metadata = {
    name: "Staking KHANS",
    description: "staking.khans.io",
    url: "https://staking.khans.io/dashboard", // origin must match your domain & subdomain
    icons: ["https://staking.khans.io/favicon.ico"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnet, optimism],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    // themeMode: "light",
    // themeVariables: {
    //   "--w3m-color-mix": "#FFFFFFCC",
    //   "--w3m-color-mix-strength": 40,
    // },
  });
  return walletProvider;
};

export default useWalletProvider;
