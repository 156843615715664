import Swal from "sweetalert2";
import { StakingUniV2Address, StakingUniV2ABI } from "../../../config/new/StakingUniV2Config";
import { ethers } from "ethers";

function uniV2ClaimAct(account, walletProvider) {
  return async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum); // Web3Modal을 통해 선택된 지갑의 provider를 사용합니다.
      const signer = provider.getSigner(); // 서명자(Signer)를 가져옵니다.
      const contract = new ethers.Contract(StakingUniV2Address, StakingUniV2ABI, signer); // 스마트 컨트랙트 인스턴스를 생성합니다.

      const claimReward = await contract.claimReward(); // claimReward 함수를 호출합니다.
      await claimReward.wait(); // 트랜잭션이 마이닝될 때까지 기다립니다.

      // const claimeReward = await StakingUniV2Contract.methods.claimReward().send({ from: account });

      Swal.fire({
        title: "Success",
        text: "Claim was successful!",
        icon: "success",
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Fail",
        text: "Claim was Fail!",
        icon: "error",

        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
      // .then((result) => {
      //   if (result.isConfirmed) {
      //     window.location.reload();
      //   }
      // });
    }
  };
}

export const uniV2ClaimAction = { uniV2ClaimAct };
