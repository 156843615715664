let initialState = {
  getAmount: "",
  stakingTokenBalance: 0,
  canAmountStake: "",
  allowanceAmount: 0,
  HanQuantityLpQuantityPerYear1HanValue: "",
  successStaking: false,
  succuessClaim: false,
  successUnStaking: false,
  successApprove: false,
};

function stakingViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "GET_STAKING_VIEW_SUCCESS":
      return {
        ...state,
        getAmount: payload.getAmount,
        stakingTokenBalance: payload.stakingTokenBalance,
        canAmountStake: payload.canAmountStake,
        allowanceAmount: payload.allowanceAmount,
        HanQuantityLpQuantityPerYear1HanValue: payload.HanQuantityLpQuantityPerYear1HanValue,
      };

    case "SUCCESS_STAKING":
      return { ...state, successStaking: payload.successStaking };

    case "SUCCESS_CLAIM":
      return { ...state, succuessClaim: payload.succuessClaim };

    case "SUCCESS_UNSTAKING":
      return { ...state, successUnStaking: payload.successUnStaking };

    case "SUCCESS_APPORVE":
      return { ...state, successApprove: payload.successApprove };

    default:
      return { ...state };
  }
}

export default stakingViewReducer;
