let initialState = {
  getHanLaunch1Apr: "",
  getHanLaunch2Apr: "",
  getHanLaunch3Apr: "",
  getHanLaunch4Apr: "",
};

function liquidityRewardViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "GET_HANLAUNCH_GET_APR":
      return {
        ...state,
        getHanLaunch1Apr: payload.getHanLaunch1Apr,
        getHanLaunch2Apr: payload.getHanLaunch2Apr,
        getHanLaunch3Apr: payload.getHanLaunch3Apr,
        getHanLaunch4Apr: payload.getHanLaunch4Apr,
      };

    default:
      return {
        ...state,
      };
  }
}

export default liquidityRewardViewReducer;
