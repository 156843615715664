import React from "react";
import "./MainStakingSection.scss";
import {
  HanLogo,
  LaunchPadLogo,
  MainArrakisLogo,
  QuestionIcon,
  SprLogoBackX,
  uniSwapPinkLogo,
} from "../../assets/_index";

const MainStakingSection = () => {
  return (
    <div className='mainPageStakingContainer'>
      <div className='mainPageStakingBoxSection'>
        <div className='mainPageStakingTitleSection'>
          <p>STAKING</p>
        </div>
        <div className='mainPageClaimLinkSection'>
          <a href='dashboard' target='_blank' rel='noreferrer noopener' className='claimLinkTxt'>
            CLAIM
          </a>
        </div>
        <div className='stakingBoxSection'>
          <div className='stakingLogoSection'>
            <img src={uniSwapPinkLogo} alt='UniSwapLogo' />
          </div>
          <div className='stakingBoxTxtSection'>
            <span>Launchpad WBTC/HAN</span>
          </div>
          <div className='tooltip-main-spri-container'>
            <a
              href='https://github.com/hanchain-paykhan/launchpad.khans.io/tree/main/HanChain'
              target='_blank'
              rel='noreferrer noopener'
            >
              <img src={QuestionIcon} className='info-icon material-main-spri-icons' alt='QuestionIcon'></img>
            </a>
          </div>
          <div className='stakingBoxLaunchHANBtnSection'>
            <a
              className='stakingBoxBtn'
              href='/launchpad'
              // href='https://launchpad.khans.io/hanchain'
              target='_blank'
              rel='noreferrer noopener'
            >
              GO
            </a>
          </div>
        </div>
        <div className='stakingBoxSection'>
          <div className='stakingLogoSection'>
            <img src={uniSwapPinkLogo} alt='UniSwapLogo' />
          </div>
          <div className='stakingBoxTxtSection'>
            <span>Launchpad WBTC/HANeP</span>
          </div>
          <div className='tooltip-main-spri-container'>
            <a
              href='https://github.com/hanchain-paykhan/launchpad.khans.io/tree/main/HanChain'
              target='_blank'
              rel='noreferrer noopener'
            >
              <img src={QuestionIcon} className='info-icon material-main-spri-icons' alt='QuestionIcon'></img>
            </a>
          </div>
          <div className='stakingBoxLaunchHANePBtnSection'>
            <a
              className='stakingBoxBtn'
              href='https://launchpad.khans.io/hanep'
              target='_blank'
              rel='noreferrer noopener'
            >
              GO
            </a>
          </div>
        </div>
        <div className='stakingBoxSection'>
          <div className='stakingLogoSection'>
            <img src={HanLogo} alt='HanLogo' />
          </div>
          <div className='stakingBoxTxtSection'>
            <span>HAN BONUS STAKING</span>
          </div>
          <div className='tooltip-main-spri-container'>
            <a
              href='https://github.com/hanchain-paykhan/staking.khans.io/tree/main/contract/hanchain'
              target='_blank'
              rel='noreferrer noopener'
            >
              <img src={QuestionIcon} className='info-icon material-main-spri-icons' alt='QuestionIcon'></img>
            </a>
          </div>
          <div className='stakingBoxHanBtnSection'>
            <a className='stakingBoxBtn' href='/hanbonus' target='_blank' rel='noreferrer noopener'>
              GO
            </a>
          </div>
        </div>
        <div className='stakingBoxSection'>
          <div className='stakingLogoSection'>
            <img src={LaunchPadLogo} alt='MusikhanLogo' />
          </div>
          <div className='stakingBoxTxtSection'>
            <span>Launchpad PAD NFT</span>
          </div>
          <div className='tooltip-main-spri-container'>
            <a
              href='https://github.com/hanchain-paykhan/launchpad.khans.io/tree/main/musikhan#transferred-nft-symbol'
              target='_blank'
              rel='noreferrer noopener'
            >
              <img src={QuestionIcon} className='info-icon material-main-spri-icons' alt='QuestionIcon'></img>
            </a>
          </div>
          <div className='stakingBoxLaunchBtnSection'>
            <a
              className='stakingBoxBtn'
              href='https://launchpad.khans.io/staking'
              target='_blank'
              rel='noreferrer noopener'
            >
              GO
            </a>
          </div>
        </div>
        <div className='stakingBoxSection'>
          <div className='stakingLogoSection'>
            <img src={SprLogoBackX} alt='SprLogo' />
          </div>
          <div className='stakingBoxTxtSection'>
            <span>Sheepoori SPR NFT</span>
          </div>
          <div className='tooltip-main-spri-container'>
            <img src={QuestionIcon} className='info-icon material-main-spri-icons' alt='QuestionIcon'></img>
            <div className='tooltip-main-spri-content'>
              <p>
                The right to possess digital content forever and get yourself a Sheepoori card -Ms. Caring one of three
                sheep siblings characters from Sewoori Union for AdKhan: Advertising Platform
                <br></br>
                <a
                  className='align-main-spri-right'
                  href='https://medium.com/@HanIdentity/as-the-second-staking-of-the-hanchain-project-e29da8da25e3'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  Read More
                </a>
              </p>
            </div>
          </div>
          <div className='stakingBoxSpriBtnSection'>
            <a className='stakingBoxBtn' href='/spr' target='_blank' rel='noreferrer noopener'>
              GO
            </a>
          </div>
        </div>
        <div className='stakingBoxSection'>
          <div className='stakingLogoSection'>
            <img src={uniSwapPinkLogo} alt='ArrakisLogo' />
          </div>
          <div className='stakingBoxTxtSection'>
            <span>Uniswap V2 USDC/HANeP</span>
          </div>

          <div className='stakingBoxHanepBtnSection'>
            <a className='stakingBoxBtn' href='/univ2' target='_blank' rel='noreferrer noopener'>
              GO
            </a>
          </div>
        </div>
        <div className='stakingBoxSection'>
          <div className='stakingLogoSection'>
            <img src={MainArrakisLogo} alt='ArrakisLogo' />
          </div>
          <div className='stakingBoxTxtSection'>
            <span>Uniswap V3 Arrakis Vault </span>
          </div>
          <div className='tooltip-main-spri-container'>
            <img src={QuestionIcon} className='info-icon material-main-spri-icons' alt='QuestionIcon'></img>
            <div className='tooltip-main-spri-content'>
              <p>
                APR displayed is not historical statistics. According to the LP token quantity standard that fluctuates
                with the HAN weight of the POOL, when staking at the present time, APR is the annual interest rate of
                the amount of HAN to be obtained against the liquidity supplied.
                <br></br>
                <a
                  className='align-main-spri-right'
                  href='https://medium.com/@HanIdentity/hanchain-x-optimism-x-uniswap-v3-x-arrakis-af564de80f81'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  Read More
                </a>
              </p>
            </div>
          </div>
          <div className='stakingBoxArrakisBtnSection'>
            <a className='stakingBoxBtn' href='/rakis6' target='_blank' rel='noreferrer noopener'>
              GO
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MainStakingSection);
