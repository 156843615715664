import Swal from "sweetalert2";
import { StakingUniV2Address, StakingUniV2ABI } from "../../../config/new/StakingUniV2Config";
import { ethers } from "ethers";

function uniV2UnStakeAct(account, hanRakis6UnStakeNum, walletProvider) {
  return async (dispatch) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum); // Web3Modal을 통해 선택된 지갑의 provider를 사용합니다.
      const signer = provider.getSigner(); // 서명자(Signer)를 가져옵니다.
      const contract = new ethers.Contract(StakingUniV2Address, StakingUniV2ABI, signer); // 스마트 컨트랙트 인스턴스를 생성합니다.

      const withdraw = await contract.withdraw(hanRakis6UnStakeNum); // claimReward 함수를 호출합니다.
      await withdraw.wait(); // 트랜잭션이 마이닝될 때까지 기다립니다.
      // const withdraw = await StakingUniV2Contract.methods.withdraw(hanRakis6UnStakeNum).send({ from: account });

      Swal.fire({
        title: "Success",
        text: "UnStake was successful!",
        icon: "success",
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      dispatch({
        type: "SUCCESS_UNIV2_UNSTAKE",
        payload: {},
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Fail",
        text: "UnStaking was Fail!",
        icon: "error",

        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };
}

export const uniV2UnStakeAction = { uniV2UnStakeAct };
