let initialState = {
  getOldSprAmountStaked: "",
  myTokenId: "",
  myStakedTokenId: "",
  successOldSprApprove: false,
  getOldSprDepositList: [],
  getOldSPRStakedNft: [],
};

function oldSprStakingViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "GET_OLD_SPR_STAKING_VIEW_SUCCESS":
      return {
        ...state,
        getOldSprAmountStaked: payload.getOldSprAmountStaked,
      };

    case "SUCCESS_OLD_SPR_APPROVE":
      return { ...state, successOldSprApprove: payload.successOldSprApprove };

    case "SELECT_OLD_STAKING_NFT":
      return { ...state, myTokenId: payload };

    case "SELECT_OLD_UNSTAKING_NFT":
      return { ...state, myStakedTokenId: payload };
    case "OLD_SPR_DEPOSIT_LIST":
      return { ...state, getOldSprDepositList: payload.getOldSprDepositList };
    case "OLD_SPR_WITHDRAW_LIST":
      return { ...state, getOldSPRStakedNft: payload.getOldSPRStakedNft };

    default:
      return { ...state };
  }
}

export default oldSprStakingViewReducer;
