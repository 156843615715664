const initialState = {
  launchNftResultValue: "",
  transferred: "",
  getHanLaunch1Apr: "",
  getHanLaunch2Apr: "",
  getHanLaunch3Apr: "",
  getHanLaunch4Apr: "",
  getLiquidityReward1View: "",
  liquidity1StakedAmount: "",
  getLiquidityReward2View: "",
  liquidity2StakedAmount: "",
  getLiquidityReward3View: "",
  liquidity3StakedAmount: "",
  getLiquidityReward4View: "",
  liquidity4StakedAmount: "",
  getHANePLaunch1Apr: "",
  getHANePLaunch4Apr: "",
  getLiquidityRewardHANeP1View: "",
  liquidityHANeP1StakedAmount: "",
  getLiquidityRewardHANeP4View: "",
  liquidityHANeP4StakedAmount: "",
  dashBoardHanBonusView: "",
  dashBoardPriUniV2View: "",
  dashBoardHanChainPrice: "",
  dashBoardHANePPrice: "",
  getHANePBonusAPR: "",
  getHanEpBonusV2APR: "",
  getHanEpBonusV3APR: "",
};

const dashBoardViewReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "DASH_LAUNCHNFT_RESULT_VIEW":
      return {
        ...state,
        launchNftResultValue: payload.launchNftResultValue,
        transferred: payload.transferred,
      };
    case "GET_DASH_LIQIDITY_REWARD_APR":
      return {
        ...state,
        getHanLaunch1Apr: payload.getHanLaunch1Apr,
        getHanLaunch2Apr: payload.getHanLaunch2Apr,
        getHanLaunch3Apr: payload.getHanLaunch3Apr,
        getHanLaunch4Apr: payload.getHanLaunch4Apr,
      };
    case "GET_LIQDITY_REWARD1_VIEW":
      return {
        ...state,
        getLiquidityReward1View: payload.getLiquidityReward1View,
        liquidity1StakedAmount: payload.liquidity1StakedAmount,
      };
    case "GET_LIQDITY_REWARD2_VIEW":
      return {
        ...state,
        getLiquidityReward2View: payload.getLiquidityReward2View,
        liquidity2StakedAmount: payload.liquidity2StakedAmount,
      };
    case "GET_LIQDITY_REWARD3_VIEW":
      return {
        ...state,
        getLiquidityReward3View: payload.getLiquidityReward3View,
        liquidity3StakedAmount: payload.liquidity3StakedAmount,
      };
    case "GET_LIQDITY_REWARD4_VIEW":
      return {
        ...state,
        getLiquidityReward4View: payload.getLiquidityReward4View,
        liquidity4StakedAmount: payload.liquidity4StakedAmount,
      };
    case "GET_DASH_LIQDITY_REWARD_HANEP_APR":
      return {
        ...state,
        getHANePLaunch1Apr: payload.getHANePLaunch1Apr,
        getHANePLaunch4Apr: payload.getHANePLaunch4Apr,
      };
    case "GET_LIQDITY_REWARD_HANEP1_VIEW":
      return {
        ...state,
        getLiquidityRewardHANeP1View: payload.getLiquidityRewardHANeP1View,
        liquidityHANeP1StakedAmount: payload.liquidityHANeP1StakedAmount,
      };
    case "GET_LIQUIDITY_REWARD_HANeP4_VIEW":
      return {
        ...state,
        getLiquidityRewardHANeP4View: payload.getLiquidityRewardHANeP4View,
        liquidityHANeP4StakedAmount: payload.liquidityHANeP4StakedAmount,
      };
    case "DASH_HANBONUS_UNIV2_RESULT_VIEW":
      return {
        ...state,
        dashBoardHanBonusView: payload.dashBoardHanBonusView,
        dashBoardPriUniV2View: payload.dashBoardPriUniV2View,
      };
    case "DASH_BOARD_TOKEN_PRICE":
      return {
        ...state,
        dashBoardHanChainPrice: payload.dashBoardHanChainPrice,
        dashBoardHANePPrice: payload.dashBoardHANePPrice,
        getHANePBonusAPR: payload.getHANePBonusAPR,
        getHanEpBonusV2APR: payload.getHanEpBonusV2APR,
        getHanEpBonusV3APR: payload.getHanEpBonusV3APR,
      };

    default:
      return { ...state };
  }
};

export default dashBoardViewReducer;
