import axios from "axios";

export function hanStakingRewardViewAct(account) {
  return async (dispatch) => {
    try {
      const hanRewardPerSecondViewApi = await axios.post(`https://back.khans.io/block/hanBonusRewardView`, {
        account,
      });
      const hanTotalRewardReleasedApi = await axios.post(`https://back.khans.io/block/hanBonusTotalRewardReleased`, {
        account,
      });
      let [hanRewardPerSecondView, hanTotalRewardReleased] = await Promise.all([
        hanRewardPerSecondViewApi.data,
        hanTotalRewardReleasedApi.data,
      ]);

      dispatch({
        type: "HAN_CHAIN_REWARD_VIEW",
        payload: {
          hanRewardPerSecondView: hanRewardPerSecondView,
          hanTotalRewardReleased: hanTotalRewardReleased,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export const hanStakingRewardViewAction = { hanStakingRewardViewAct };
