let initialState = {
  wbtcADWhiteListAmount: false,
  wbtcADBtcAmount: "",
  wbtcAirDropRewardView: "",
  totalLiquidity: "",
  totalRewardReleased: "",
  unclaimedRewards: "",
  totalHanAmount: "",
  getwbtcAirDropTotalSupply: "",
  wbtcAirDropAllData: {},
  wbtcAirDropIndexToModal: "",
  wbtcAirDropAmountToModal: "",
  wbtcAirDropHANAmountToModal: "",
  getLockedCalculateHan: "",
  getLockedCalculateWBTC: "",
  getwbtcAirDropApr: "",
};

function wbtcADLiquidityLockedViewReducer(state = initialState, action) {
  let { type, payload } = action;
  switch (type) {
    case "GET_WBTC_REFERRER_VIEW":
      return {
        ...state,
        wbtcADWhiteListAmount: payload.wbtcADWhiteListAmount,
        wbtcADBtcAmount: payload.wbtcADBtcAmount,
      };

    case "GET_WBTC_REWARD_VIEW":
      return {
        ...state,
        wbtcAirDropRewardView: payload.wbtcAirDropRewardView,
      };

    case "GET_WBTC_TOTAL_REFERRER_VIEW":
      return {
        ...state,
        totalLiquidity: payload.totalLiquidity,
        totalRewardReleased: payload.totalRewardReleased,
        unclaimedRewards: payload.unclaimedRewards,
        totalHanAmount: payload.totalHanAmount,
        getwbtcAirDropTotalSupply: payload.getwbtcAirDropTotalSupply,
      };
    case "GET_WBTC_ALL_DATA_VIEW":
      return {
        ...state,
        wbtcAirDropAllData: payload.wbtcAirDropAllData,
      };
    case "WBTC_MODAL_TO_PAGE_VIEW":
      return {
        ...state,
        wbtcAirDropIndexToModal: payload.wbtcAirDropIndexToModal,
        wbtcAirDropAmountToModal: payload.wbtcAirDropAmountToModal,
        wbtcAirDropHANAmountToModal: payload.wbtcAirDropHANAmountToModal,
      };
    case "LIQUIDITY_CALCULATE_VIEW":
      return {
        ...state,
        getLockedCalculateHan: payload.getLockedCalculateHan,
        getLockedCalculateWBTC: payload.getLockedCalculateWBTC,
      };
    case "GET_WBTC_APR_VIEW":
      return {
        ...state,
        getwbtcAirDropApr: payload.getwbtcAirDropApr,
      };

    default:
      return { ...state };
  }
}

export default wbtcADLiquidityLockedViewReducer;
