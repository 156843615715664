import axios from "axios";

function SPRV2WithdrawListAct(account) {
  return async (dispatch) => {
    try {
      if (account !== "") {
        // const sprV2WithdrawListBackApi = await axios.post(`http://localhost:4000/block/sprV2WithdrawList`, {
        const sprV2WithdrawListBackApi = await axios.post(`https://back.khans.io/block/sprV2WithdrawList`, {
          account,
        });
        // console.log("MunieWithdrawList", sprV2WithdrawListBackApi);

        let [sprV2WithdrawListBack] = await Promise.all([sprV2WithdrawListBackApi.data]);

        dispatch({
          type: "SPRV2_WITHDRAW_LIST",
          payload: {
            sprV2WithdrawListBack: sprV2WithdrawListBack,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export const SPRV2WithdrawListAction = { SPRV2WithdrawListAct };
