const initialState = {
  rakis49PoolBalanceOf: "",
  rakis49PoolRemoveWeth: "",
  rakis49PoolRemoveHaneP: "",
  rakis49PoolLpAllowance: "",
  successRakis49LpApprove: false,
};

const rakis49PoolRemoveViewReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "GET_RAKIS49_POOL_BALANCE":
      return {
        ...state,
        rakis49PoolBalanceOf: payload.rakis49PoolBalanceOf,
      };
    case "GET_RAKIS49_LP_VIEW":
      return {
        ...state,
        rakis49PoolRemoveWeth: payload.rakis49PoolRemoveWeth,
        rakis49PoolRemoveHaneP: payload.rakis49PoolRemoveHaneP,
      };
    case "GET_RAKIS49_POOL_LP_ALLOWANCE":
      return {
        ...state,
        rakis49PoolLpAllowance: payload.rakis49PoolLpAllowance,
      };
    case "RAKIS49_POOL_LP_APPROVE_SUCCESS":
      return {
        ...state,
        successRakis49LpApprove: payload.successRakis49LpApprove,
      };

    default:
      return { ...state };
  }
};

export default rakis49PoolRemoveViewReducer;
