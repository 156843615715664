import { useCallback } from "react";
import Swal from "sweetalert2";

const useNetworkSwitcher = () => {
  const switchToMainnet = useCallback(async () => {
    try {
      await window.ethereum?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }], // 메인넷의 체인 ID
      });
    } catch (error) {
      console.error("Error switching to Mainnet:", error);
    }
  }, []);

  const switchToOptimism = useCallback(async () => {
    try {
      await window.ethereum?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0xa" }], // 옵티미즘의 체인 ID
      });
    } catch (error) {
      if (error.code === 4902) {
        try {
          Swal.fire({
            title: "Switch Network",
            html: "Project requires that you switch your wallet to the Optimism network to continue.",
            showConfirmButton: false,
          });
        } catch (addError) {
          console.error("Error adding Optimism network:", addError);
        }
      } else {
        console.error("Error switching to Optimism:", error);
      }
    }
  }, []);

  return { switchToMainnet, switchToOptimism };
};

export default useNetworkSwitcher;
