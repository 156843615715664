let initialState = {
  getHANePLaunch1Apr: "",
  getHANePLaunch4Apr: "",
  getPooledHANeP: "",
};

function liquidityHANePViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "GET_HANEP_LAUNCH_GET_APR":
      return {
        ...state,
        getHANePLaunch1Apr: payload.getHANePLaunch1Apr,
        getHANePLaunch4Apr: payload.getHANePLaunch4Apr,
        getPooledHANeP: payload.getPooledHANeP,
      };

    default:
      return {
        ...state,
      };
  }
}

export default liquidityHANePViewReducer;
