import axios from "axios";

function uniV2RewardVIewAct(account) {
  return async (dispatch) => {
    try {
      if (account) {
        // 실시간 보상 받을 수 있는 토큰 양
        const getUniV2RewardView = await axios.post(`https://back.khans.io/block/uniV2RewardView`, {
          account,
        });

        dispatch({
          type: "UNIV2_STAKING_REWARD_VIEW",
          payload: {
            uniV2RewardView: getUniV2RewardView.data,
          },
        });
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export const uniV2RewardVIewAction = { uniV2RewardVIewAct };
