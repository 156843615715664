let initialState = {
  hanEpBonusV2Allowance: "",
  hanEpBonusV2BalanceOf: "",
  getHanEpBonusV2APR: "",
  hanepBonusV2RewardView: "",
  totalDividendAmount: "",
  totalStakedAmount: "",
  totalRewardReleased: "",
  unclaimedRewards: "",
  getHanEpBonusV2TotalSupply: "",
  totalHANePStakedAmount: "",
  successHANePBonusV2Approve: false,
  hanEpBonusV2AllData: [],
  hanEpBonusV2IndexToModal: "",
  hanEpBonusV2AmountToModal: "",
  hanEpBonusV2WithdrawTimeToModal: "",
};

function hanEpBonusV2ViewReducer(state = initialState, action) {
  let { type, payload } = action;
  switch (type) {
    case "GET_HANEPBONUS_V2_VIEW":
      return {
        ...state,
        hanEpBonusV2Allowance: payload.hanEpBonusV2Allowance,
        hanEpBonusV2BalanceOf: payload.hanEpBonusV2BalanceOf,
        getHanEpBonusV2APR: payload.getHanEpBonusV2APR,
      };
    case "GET_HANEPBONUS_V2_REWARD_VIEW":
      return {
        ...state,
        hanepBonusV2RewardView: payload.hanepBonusV2RewardView,
      };
    case "GET_HANEPBONUS_V2_TOTAL_REFERRER_VIEW":
      return {
        ...state,
        totalDividendAmount: payload.totalDividendAmount,
        totalStakedAmount: payload.totalStakedAmount,
        totalRewardReleased: payload.totalRewardReleased,
        unclaimedRewards: payload.unclaimedRewards,
        getHanEpBonusV2TotalSupply: payload.getHanEpBonusV2TotalSupply,
        totalHANePStakedAmount: payload.totalHANePStakedAmount,
      };
    case "HANEP_BONUS_V2_APPROVE_SUCCESS":
      return {
        ...state,
        successHANePBonusV2Approve: payload.successHANePBonusV2Approve,
      };
    case "GET_HANEPBONUS_V2_ALL_DATA_VIEW":
      return {
        ...state,
        hanEpBonusV2AllData: payload.hanEpBonusV2AllData,
      };
    case "HANEPBONUS_V2_MODAL_TO_PAGE_VIEW":
      return {
        ...state,
        hanEpBonusV2IndexToModal: payload.hanEpBonusV2IndexToModal,
        hanEpBonusV2AmountToModal: payload.hanEpBonusV2AmountToModal,
        hanEpBonusV2WithdrawTimeToModal: payload.hanEpBonusV2WithdrawTimeToModal,
      };

    default:
      return { ...state };
  }
}

export default hanEpBonusV2ViewReducer;
