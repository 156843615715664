import axios from "axios";

function SPRV2StakingViewAct(account) {
  return async (dispatch) => {
    try {
      const sprV2StakerDataApi = await axios.post(`https://back.khans.io/block/sprV2StakerData`, {
        account,
      });

      let [sprV2StakedAmount] = await Promise.all([sprV2StakerDataApi.data.countStaked]);
      dispatch({
        type: "SPRV2_STAKING_VIEW",
        payload: {
          sprV2StakedAmount: sprV2StakedAmount,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export const SPRV2StakingViewAction = { SPRV2StakingViewAct };
