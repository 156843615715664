let initialState = {
  HANePLPWBTCBalance: "",
  HANePAmount: "",
  wbtcAmount: "",
  HANePLPTotalReward: "",
  HANePLPUnClaimedRewards: "",
  lockupPeriod: 0,
  hasAddedLiquidity: false,
  HANePLaunchpadUSDPrice: "",
  getPooledHANeP: "",
  HANePLaunchpadRewardView: "",
  getHANePLaunchCalculateHANeP: "",
  getHANePLaunchCalculateWBTC: "",
};

function liquidityHANeP1ViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "HANEP_750_LAUNCH_VIEW":
      return {
        ...state,
        HANePLPWBTCBalance: payload.HANePLPWBTCBalance,
        HANePAmount: payload.HANePAmount,
        wbtcAmount: payload.wbtcAmount,
        HANePLPTotalReward: payload.HANePLPTotalReward,
        HANePLPUnClaimedRewards: payload.HANePLPUnClaimedRewards,
        lockupPeriod: payload.lockupPeriod,
        hasAddedLiquidity: payload.hasAddedLiquidity,
        HANePLaunchpadUSDPrice: payload.HANePLaunchpadUSDPrice,
        getPooledHANeP: payload.getPooledHANeP,
      };

    case "HANEP_LAUNCH_750_REWARD_VIEW":
      return {
        ...state,
        HANePLaunchpadRewardView: payload.HANePLaunchpadRewardView,
      };

    case "HANEP_750_LAUNCH_CALCULATE_VIEW":
      return {
        ...state,
        getHANePLaunchCalculateHANeP: payload.getHANePLaunchCalculateHANeP,
        getHANePLaunchCalculateWBTC: payload.getHANePLaunchCalculateWBTC,
      };

    default:
      return {
        ...state,
      };
  }
}

export default liquidityHANeP1ViewReducer;
