import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./HanDepositSection.scss";
import { YearsLockedIcon } from "../../assets/_index";
import { hanStakingViewAction } from "../../redux/actions/hanStakingActions/hanStakingViewActions";
import { hanStakingStakeAction } from "../../redux/actions/hanStakingActions/hanStakingStakeAction";
import { hanStakingApproveAction } from "../../redux/actions/hanStakingActions/hanStakingApproveAction";
import { hanStakingApproveStateAction } from "../../redux/actions/hanStakingActions/hanStakingApproveStateAction";
import Loading from "../GloBal/Loading";
import useChainId from "../../hooks/useChainId";
import useWalletProvider from "../../hooks/useWalletProvider";
import { ethers } from "ethers";

const HanDepositSection = () => {
  const dispatch = useDispatch();
  const walletProvider = useWalletProvider();
  const checkChainId = useChainId();
  const { account } = useSelector((state) => state.account);
  const [hanStakingAmount, setHanStakingAmount] = useState(0);

  const { hanChainBalanceOf, hanChainAllowance, successHanChainApprove } = useSelector((state) => state.hanStakingView);
  // console.log(totalSupply)

  // 소수점까지 staking
  // const changeHanDepositAmount = (e) => {
  //   const pattern = /^(\d{0,4}([.]\d{0,18})?)?$/;
  //   if (pattern.test(e.target.value)) {
  //     setHanStakingAmount(e.target.value);
  //   }
  // };

  // 정수까지 staking
  const changeHanDepositAmount = (e) => {
    const pattern = /^\d*$/; // 정수만 허용하는 정규식으로 변경
    if (pattern.test(e.target.value)) {
      setHanStakingAmount(e.target.value);
    }
  };
  // 소수점까지 staking
  // const changeMaxHanDepositAmount = () => {
  //   setHanStakingAmount(hanChainBalanceOf);
  // };

  // 정수까지 staking
  const changeMaxHanDepositAmount = () => {
    setHanStakingAmount(Math.floor(hanChainBalanceOf)); // 소수점 이하를 버리고 정수 부분만 설정
  };

  // console.log("walletProvider", walletProvider);

  const setHanChainApprove = () => {
    let hanStakingAmount = document.getElementById("maxHanChainStakeAmount").value;
    const hanChainStakingNum = ethers.utils.parseEther(String(hanStakingAmount));
    // const hanChainStakingNum = Web3.utils.toWei(String(hanStakingAmount), "ether");
    dispatch(hanStakingApproveAction.hanStakingApproveAct(account, hanChainStakingNum, walletProvider));
  };

  const setHanChainStake = () => {
    let hanStakingAmount = document.getElementById("maxHanChainStakeAmount").value;
    const hanChainStakingNum = ethers.utils.parseEther(String(hanStakingAmount));
    dispatch(hanStakingStakeAction.hanStakingStakeAct(account, hanChainStakingNum, walletProvider));
  };

  useEffect(() => {
    if (account) {
      dispatch(hanStakingViewAction.hanStakingViewAct(account));
      dispatch(hanStakingApproveStateAction.hanStakingApproveStateAct(account));
    }
  }, [account, dispatch]);

  return (
    <>
      {checkChainId === "0xa" ? (
        <>
          <div className='han-Staking-Deposit-TokenBalanceSection'>
            <p>Available : {hanChainBalanceOf} </p>
          </div>
          <div className='han-Staking-Deposit-Approve-AmountSection'>
            <input
              type='number'
              step='0.000000000000000001'
              id='maxHanChainStakeAmount'
              placeholder='0'
              onChange={changeHanDepositAmount}
              value={hanStakingAmount}
            ></input>
            <p>HAN</p>
            <button className='han-Staking-Deposit-AmountMaxBtn' onClick={changeMaxHanDepositAmount}>
              Max
            </button>
          </div>
          <div className='han-Staking-Deposit-Pswd-Container'>
            <div className='han-Staking-Deposit-Pswd-Section'>
              <div className='han-Staking-Deposit-Pswd-Lock'>
                <img src={YearsLockedIcon} alt='yearsLockedIcon' />
              </div>
              <input name='stakingPassword' placeholder='365 Days Locked' readOnly />
            </div>
          </div>
          {hanStakingAmount === "" || hanStakingAmount === 0 ? (
            <div className='han-Staking-DepositStakeBtnSection'>
              <button className='han-Staking-Deposit-CantBtn'>ENTER AMOUNT</button>
            </div>
          ) : hanChainBalanceOf === "0.0" ||
            parseFloat(hanStakingAmount) > parseFloat(hanChainBalanceOf) ||
            hanStakingAmount === "00" ? (
            <div className='han-Staking-DepositStakeBtnSection'>
              <button className='han-Staking-Deposit-CantBtn' disabled={true}>
                INSUFFICIENT BALANCE
              </button>
            </div>
          ) : parseFloat(hanChainAllowance) > parseFloat(hanChainBalanceOf) ||
            parseFloat(hanChainAllowance) === "0.0" ? (
            <div className='han-Staking-DepositStakeBtnSection'>
              <button onClick={setHanChainApprove} className='han-Staking-Deposit-CanBtn'>
                APPROVE
              </button>
            </div>
          ) : parseFloat(hanStakingAmount) <= parseFloat(hanChainAllowance) ? (
            <div className='han-Staking-DepositStakeBtnSection'>
              <button onClick={setHanChainStake} className='han-Staking-Deposit-CanBtn'>
                STAKE
              </button>
            </div>
          ) : successHanChainApprove === false ? (
            <div className='han-Staking-DepositStakeBtnSection'>
              <button onClick={setHanChainApprove} className='han-Staking-Deposit-CanBtn'>
                APPROVE
              </button>
            </div>
          ) : (
            <div className='han-Staking-DepositStakeBtnSection'>
              <button onClick={setHanChainStake} className='han-Staking-Deposit-CanBtn'>
                STAKE
              </button>
            </div>
          )}
        </>
      ) : (
        <div className='han-Staking-Deposit-LoadingContainer'>
          <Loading />
        </div>
      )}
    </>
  );
};

export default React.memo(HanDepositSection);
