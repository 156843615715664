import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RefreshIcon, ClickRedIcon } from "../../assets/_index";
import "./HanWithdrawSection.scss";
import { hanStakingUnStakeAction } from "../../redux/actions/hanStakingActions/hanStakingUnStakeAction";
import HanWithdrawModal from "./Modal/HanWithdrawModal";
import { hanStakingTokenListViewAction } from "../../redux/actions/hanStakingActions/hanStakingTokenListViewAction";
import { hanStakingRemainingDurationAction } from "../../redux/actions/hanStakingActions/hanStakingRemainingDurationAction";
import Loading from "../GloBal/Loading";
import useWalletProvider from "../../hooks/useWalletProvider";
import useChainId from "../../hooks/useChainId";

const HanWithdrawSection = () => {
  const dispatch = useDispatch();
  const walletProvider = useWalletProvider();
  const checkChainId = useChainId();
  const [hanStakingWithdrawModal, setHanStakingWithdrawModal] = useState(false);
  const { account } = useSelector((state) => state.account);
  // const [hanWithdrawAmount, setHanWithdrawAmount] = useState("");
  const { hanWithdrawAmountToModal, hanWithdrawIndex } = useSelector((state) => state.hanStakingView);
  const { hanClaimDayDate, hanClaimHoursDate, hanClaimMinDate, hanClaimSecDate } = useSelector(
    (state) => state.hanStakingDuration
  );

  // console.log(hanWithdrawIndex);

  const openHanWithdrawModal = () => {
    setHanStakingWithdrawModal(true);
  };

  const closeHanWithdrawModal = () => {
    setHanStakingWithdrawModal(false);
  };

  const changeHanTimeStampState = () => {
    dispatch(hanStakingRemainingDurationAction.hanStakingRemainingDurationAct(account, hanWithdrawIndex));
  };

  const setHanUnStake = () => {
    dispatch(hanStakingUnStakeAction.hanStakingUnStakeAct(account, hanWithdrawIndex, walletProvider));
  };

  useEffect(() => {
    dispatch(hanStakingTokenListViewAction.hanStakingTokenListViewAct(account));
  }, [account, dispatch]);

  return (
    <>
      {checkChainId === "0xa" ? (
        <>
          <div className='han-Staking-Withdraw-AmountSection'>
            <input
              type='number'
              step='0.000000000000000001'
              id='maxHanUnStakeAmount'
              placeholder='0'
              // onChange={changeHanWithdrawAmount}
              defaultValue={hanWithdrawAmountToModal}
            ></input>
            <p>HAN</p>
            {/* <button className="han-Staking-Withdraw-AmountMaxBtn">Max</button> */}
            {/* <button className="han-Staking-Withdraw-AmountMaxBtn" onClick={changeMaxHanWithdrawAmount}>
                   Max
               </button> */}
            <button className='han-Staking-Select-Token-Btn' onClick={openHanWithdrawModal}>
              SELECT
              <img src={ClickRedIcon} className='han-Staking-Select-Token-Icon' alt='clickIcon' />
            </button>
            <HanWithdrawModal open={hanStakingWithdrawModal} close={closeHanWithdrawModal} header='Rakis6 Modal' />
          </div>
          <div className='han-Staking-WithDraw-TimeContainer'>
            <div className='han-Staking-WithDraw-TimeTitle'>
              <b>Remaining Duration</b>
            </div>
            {hanClaimMinDate ? (
              <div className='han-Staking-WithDraw-TimeSection'>
                <b className='han-Staking-DayDate'>{hanClaimDayDate}D</b>
                <b className='han-Staking-HoursDate'>{hanClaimHoursDate}H</b>
                <b className='han-Staking-MinDate'>{hanClaimMinDate}M</b>
                <b className='han-Staking-SecDate'>{hanClaimSecDate}S</b>
                <img
                  src={RefreshIcon}
                  width='20px'
                  height='20px'
                  className='han-Staking-WithDraw-ReFreshIcon'
                  onClick={changeHanTimeStampState}
                  alt='refreshIcon'
                />
              </div>
            ) : (
              <div className='han-Staking-WithDraw-TimeSection'>
                <b className='han-Staking-DayDate'>N/A</b>
                <b className='han-Staking-HoursDate'>N/A</b>
                <b className='han-Staking-MinDate'>N/A</b>
                <b className='han-Staking-SecDate'>N/A</b>
                <img
                  src={RefreshIcon}
                  width='20px'
                  height='20px'
                  className='han-Staking-WithDraw-CantReFreshIcon'
                  onClick={changeHanTimeStampState}
                  alt='refreshIcon'
                />
              </div>
            )}
          </div>

          <div className='han-Staking-WithDraw-BtnContainer'>
            {hanWithdrawAmountToModal === "" ? (
              <div className='han-Staking-Withdraw-Can-BtnSection'>
                <button className='han-Staking-Withdraw-SelectBtn' disabled={true}>
                  SELECT TOKEN
                </button>
              </div>
            ) : hanClaimSecDate === "00" ? (
              <div className='han-Staking-Withdraw-Can-BtnSection'>
                <button className='han-Staking-Withdraw-CanBtn' onClick={setHanUnStake}>
                  UNSTAKE
                </button>
              </div>
            ) : (
              <div className='han-Staking-Withdraw-Can-BtnSection'>
                <button className='han-Staking-Withdraw-SelectBtn' disabled={true}>
                  UNSTAKE
                </button>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className='han-Staking-Deposit-LoadingContainer'>
            <Loading />
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(HanWithdrawSection);
