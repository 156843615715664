let initialState = {
  wethADLockedWhiteListAmount: false,
  wethADLockedEthAmount: "",
  wethADLockedRewardView: "",
  totalLiquidity: "",
  totalRewardReleased: "",
  unclaimedRewards: "",
  totalHanAmount: "",
  getWethADLockedTotalSupply: "",
  wethADLockedAllData: {},
  wethADLockedIndexToModal: "",
  wethADLockedAmountToModal: "",
  wethADLockedWithdrawTimeToModal: "",
  wethADLockedHANAmount: "",
  getLockedCalculateHan: "",
  getLockedCalculateWETH: "",
};

function wethADLiquidityLockedViewReducer(state = initialState, action) {
  let { type, payload } = action;
  switch (type) {
    case "GET_WETH_LOCKED_REFERRER_VIEW":
      return {
        ...state,
        wethADLockedWhiteListAmount: payload.wethADLockedWhiteListAmount,
        wethADLockedEthAmount: payload.wethADLockedEthAmount,
      };

    case "GET_WETH_LOCKED_REWARD_VIEW":
      return {
        ...state,
        wethADLockedRewardView: payload.wethADLockedRewardView,
      };

    case "GET_WETH_LOCKED_TOTAL_REFERRER_VIEW":
      return {
        ...state,
        totalLiquidity: payload.totalLiquidity,
        totalRewardReleased: payload.totalRewardReleased,
        unclaimedRewards: payload.unclaimedRewards,
        totalHanAmount: payload.totalHanAmount,
        getWethADLockedTotalSupply: payload.getWethADLockedTotalSupply,
      };
    case "GET_WETH_LOCKED_ALL_DATA_VIEW":
      return {
        ...state,
        wethADLockedAllData: payload.wethADLockedAllData,
      };
    case "WETH_LOCKED_MODAL_TO_PAGE_VIEW":
      return {
        ...state,
        wethADLockedIndexToModal: payload.wethADLockedIndexToModal,
        wethADLockedAmountToModal: payload.wethADLockedAmountToModal,
        wethADLockedWithdrawTimeToModal: payload.wethADLockedWithdrawTimeToModal,
        wethADLockedHANAmount: payload.wethADLockedHANAmount,
      };
    case "LOCKED_LIQUIDITY_CALCULATE_VIEW":
      return {
        ...state,
        getLockedCalculateHan: payload.getLockedCalculateHan,
        getLockedCalculateWETH: payload.getLockedCalculateWETH,
      };

    default:
      return { ...state };
  }
}

export default wethADLiquidityLockedViewReducer;
