let initialState = {
  hanEpRewardView: "",
  totalHanEpReward: "",
  hanEPAllStakedAmount: "",
  totalHanRewardReleased: "",
  totalHanAmount: "",
};

function hanEpRewardViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "GET_HANEP_REWARD_RESULT_VIEW":
      return {
        ...state,
        hanEpRewardView: payload.hanEpRewardView,
      };
    case "GET_HANEP_REWARD_VIEW":
      return {
        ...state,
        totalHanEpReward: payload.totalHanEpReward,
        hanEPAllStakedAmount: payload.hanEPAllStakedAmount,
        totalHanRewardReleased: payload.totalHanRewardReleased,
        totalHanAmount: payload.totalHanAmount,
      };

    default:
      return { ...state };
  }
}

export default hanEpRewardViewReducer;
