import React, { useRef, useState } from "react";
import "./KhansTermsOfServiceModal.scss";

const KhansTermsOfServiceModal = ({ onClose }) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const contentRef = useRef(null);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
    const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 1; // 스크롤이 거의 끝에 도달했는지 확인
    setIsButtonEnabled(isScrolledToBottom);
  };
  return (
    <div className='modalBackground'>
      <div className='modalContainer'>
        <div className='modalHeaderSection'>
          <h3>HanChain Legal Disclaimer</h3>
          <div>
            <p>
              By clicking “I Agree” below, you agree to be bound by the terms of this Agreement. As such, you fully
              understand that:
            </p>
          </div>
        </div>
        <div className='modalContent' ref={contentRef} onScroll={handleScroll}>
          <div className='modalMainInfoSection1'>
            <h3>HanChain Participation:</h3>
            <div>
              <p>▪️ HanChain is a blockchain-based decentralized finance project. You participate at your own risk.</p>
              <p>
                ▪️ HanChain is offered for use “as is” and without any guarantees regarding security. The protocol is
                made up of immutable code and can be accessed through a variety of user interfaces.
              </p>
              <p>
                ▪️ No central entity operates the HanChain protocol. Decisions related to the protocol are governed by a
                dispersed group of participants who collectively govern and maintain the protocol.
              </p>
              <p>
                ▪️ HanChain does not unilaterally offer, maintain, operate, administer, or control any trading
                interfaces. The only user interfaces maintained by HanChain are staking interfaces herein.
              </p>
              <p>
                ▪️ You can participate in the process by staking HAN tokens in accordance with the rules and parameters
                summarized here.
              </p>
              <p>
                ▪️ The rules and parameters associated with the HanChain protocol and HanChain are subject to change at
                any time.
              </p>
            </div>
          </div>
          <div className='modalMainInfoSection1'>
            <h3>Terms of Use and Governing Law:</h3>
            <div>
              <p>
                ▪️ Your use of HanChain is conditioned upon your acceptance to be bound by the HanChain Term of Use,
                which can be found here.
              </p>
              <p>
                ▪️ The laws that apply to your use of HanChain may vary based upon the jurisdiction in which you are
                located. We strongly encourage you to speak with legal counsel in your jurisdiction if you have any
                questions regarding your use of HanChain.
              </p>
            </div>
          </div>
          <div className='modalMainInfoSection1'>
            <h3>Risk Disclaimer:</h3>
            <div>
              <p>
                ▪️ By entering into this agreement, you are not agreeing to enter into a partnership. You understand
                that HanChain is a decentralized protocol provided on an “as is” basis.
              </p>
              <p>
                ▪️ You hereby release all present and future claims against HanChain related to your use of the
                protocol, the HAN token, and any other facet of the protocol.
              </p>
              <p>
                ▪️ You agree to indemnify and hold harmless HAN and its affiliates for any costs arising out of or
                relating to your use of the HanChain protocol.
              </p>
              <p>
                ▪️ You are not accessing the protocol from any Prohibited Jurisdictions as defined below, or any other
                jurisdiction listed as a Specially Designated National by the United States Office of Foreign Asset
                Control (“OFAC”).
              </p>
              <div>
                <p>⊙ Burma (Myanmar)</p>
                <p>⊙ Cuba</p>
                <p>⊙ Iran</p>
                <p>⊙ Sudan</p>
                <p>⊙ Syria</p>
                <p>⊙ Western Balkans</p>
                <p>⊙ Belarus</p>
                <p>⊙ Côte d’Ivoire</p>
                <p>⊙ Democratic Republic of the Congo</p>
                <p>⊙ Iraq</p>
                <p>⊙ Lebanon</p>
                <p>⊙ Liberia</p>
                <p>⊙ Libya</p>
                <p>⊙ North Korea</p>
                <p>⊙ Russia</p>
                <p>⊙ Certain sanctioned areas of Ukraine</p>
                <p>⊙ Somalia</p>
                <p>⊙ Venezuela</p>
                <p>⊙ Yemen</p>
                <p>⊙ Zimbabwe</p>
              </div>
            </div>
          </div>
          <div className='modalMainInfoSection1'>
            <h3>Investment Disclaimer:</h3>
            <div>
              <p>▪️ Investing always carries risks, and there is a possibility of losing your investment.</p>
              <p>
                ▪️ Investors should carefully review and understand all information related to the token before making
                an investment decision.
              </p>
              <p>▪️ HanChain is not responsible for the investment decisions of investors.</p>
            </div>
          </div>
          <div className='modalMainInfoSection1'>
            <h3>Token Value Fluctuation:</h3>
            <div>
              <p>
                ▪️ The value of the token may fluctuate depending on market conditions, and HanChain does not guarantee
                the value of the token.
              </p>
              <p>
                ▪️ Investors should be aware of the possibility of a decrease in the value of the token and invest
                accordingly.
              </p>
            </div>
          </div>
          <div className='modalMainInfoSection1'>
            <h3>Regulatory Compliance:</h3>
            <div>
              <p>
                ▪️ HanChain complies with relevant regulations and does not engage in illegal activities in the process
                of token issuance and trading.
              </p>
              <p>▪️ Investors can be confident that HanChain strives to comply with regulations.</p>
            </div>
          </div>
          <div className='modalMainInfoSection1'>
            <h3>Accuracy of Information:</h3>
            <div>
              <p>▪️ HanChain strives to ensure that all information provided is accurate and up-to-date.</p>
              <p>
                ▪️ Investors can trust the information provided by HanChain and make investment decisions based on it.
              </p>
            </div>
          </div>
          <div className='modalMainInfoSection1'>
            <h3> Limitation of Liability:</h3>
            <div>
              <p>
                ▪️ HanChain is not responsible for any losses incurred in the process of token issuance and trading.
              </p>
              <p>▪️ Investors should be aware of the possibility of investment losses and invest accordingly.</p>
            </div>
          </div>
          <div className='modalMainInfoSection1'>
            <h3> Additional Information:</h3>
            <div>
              <p>▪️ It is recommended to consult with a legal professional before investing in any token.</p>
              <p>
                ▪️ Investors should carefully review the whitepaper and other relevant documents before making an
                investment decision.
              </p>
            </div>
          </div>
        </div>
        <button
          className={`agreeButton ${!isButtonEnabled ? "disabled" : ""}`}
          onClick={onClose}
          disabled={!isButtonEnabled}
        >
          I Agree
        </button>
      </div>
    </div>
  );
};

export default KhansTermsOfServiceModal;
