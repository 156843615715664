let initialState = {
  getHanChainLaunchpadStakerData: [],
  totalLiquidity: "",
  totalHanAmount: "",
  totalWbtcAmount: "",
  totalRewardReleased: "",
  unclaimedRewards: "",
  hanChainLaunchpadRewardView: "",
  resultEthAmount: "",
  formattedUsdcValue: "",
  unLimitIndexToModal: "",
  unLimitLiquidityToModal: "",
  unLimitHanAmountToModal: "",
  unLimitLockupPeriodToModal: "",
  getHanLaunchCalculateHan: "",
  getHanLaunchCalculateWBTC: "",
};

function liquidityReward4ViewReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case "HANCHAIN_UNLIMIT_LAUNCH_VIEW":
      return {
        ...state,
        getHanChainLaunchpadStakerData: payload.getHanChainLaunchpadStakerData,
      };
    case "HANCHAIN_UNLIMIT_TOTAL_LAUNCH_VIEW":
      return {
        ...state,
        totalLiquidity: payload.totalLiquidity,
        totalHanAmount: payload.totalHanAmount,
        totalWbtcAmount: payload.totalWbtcAmount,
        totalRewardReleased: payload.totalRewardReleased,
        unclaimedRewards: payload.unclaimedRewards,
      };
    case "HANCHAIN_LAUNCH_UNLIMIT_REWARD_VIEW":
      return {
        ...state,
        hanChainLaunchpadRewardView: payload.hanChainLaunchpadRewardView,
      };

    case "HANCHAIN_LAUNCH_UNLIMIT_ETH_AMOUNT":
      return {
        ...state,
        resultEthAmount: payload.resultEthAmount,
      };
    case "HANCHAIN_LAUNCH_UNLIMIT_USDC_AMOUNT":
      return {
        ...state,
        formattedUsdcValue: payload.formattedUsdcValue,
      };
    case "HANCHAIN_UNLIMIT_MODAL_TO_PAGE_VIEW":
      return {
        ...state,
        unLimitIndexToModal: payload.unLimitIndexToModal,
        unLimitLiquidityToModal: payload.unLimitLiquidityToModal,
        unLimitHanAmountToModal: payload.unLimitHanAmountToModal,
        unLimitLockupPeriodToModal: payload.unLimitLockupPeriodToModal,
      };
    case "HANCHAIN_UNLIMIT_LAUNCH_CALCULATE_VIEW":
      return {
        ...state,
        getHanLaunchCalculateHan: payload.getHanLaunchCalculateHan,
        getHanLaunchCalculateWBTC: payload.getHanLaunchCalculateWBTC,
      };

    default:
      return {
        ...state,
      };
  }
}

export default liquidityReward4ViewReducer;
