let initialState = {
  hanBonusAllowance: false,
  hanBonusRewardAmount: "",
  hanBonusRewardView: "",
  totalRewardReleased: "",
  totalStakedAmount: "",
  totalReferrerRewardAmount: "",
  unclaimedRewards: "",
  getHanBonusTotalSupply: "",
  hanBonusAllData: {},
  hanBonusIndexToModal: "",
  hanBonusAmountToModal: "",
  hanBonusWithdrawTimeToModal: "",
};

function hanBonusViewReducer(state = initialState, action) {
  let { type, payload } = action;
  switch (type) {
    case "GET_HANBONUS_REFERRER_VIEW":
      return {
        ...state,
        hanBonusAllowance: payload.hanBonusAllowance,
        hanBonusRewardAmount: payload.hanBonusRewardAmount,
      };

    case "GET_HANBONUS_REWARD_VIEW":
      return {
        ...state,
        hanBonusRewardView: payload.hanBonusRewardView,
      };

    case "GET_HANBONUS_TOTAL_REFERRER_VIEW":
      return {
        ...state,
        totalRewardReleased: payload.totalRewardReleased,
        totalStakedAmount: payload.totalStakedAmount,
        totalReferrerRewardAmount: payload.totalReferrerRewardAmount,
        unclaimedRewards: payload.unclaimedRewards,
        getHanBonusTotalSupply: payload.getHanBonusTotalSupply,
      };
    case "GET_HANBONUS_ALL_DATA_VIEW":
      return {
        ...state,
        hanBonusAllData: payload.hanBonusAllData,
      };
    case "HANBONUS_MODAL_TO_PAGE_VIEW":
      return {
        ...state,
        hanBonusIndexToModal: payload.hanBonusIndexToModal,
        hanBonusAmountToModal: payload.hanBonusAmountToModal,
        hanBonusWithdrawTimeToModal: payload.hanBonusWithdrawTimeToModal,
      };

    default:
      return { ...state };
  }
}

export default hanBonusViewReducer;
