const OptimismRedLogo = "https://resource.khans.io/images/optimismlogo.svg";
const HanLogo = "https://resource.khans.io/images/HanLogo.svg";
const SheepooriLogo = "https://resource.khans.io/images/logo_sheepoori.svg";
const MusiLogoXBack = "https://resource.khans.io/images/MusiKhanLogo.png";
const USDCLogo = "https://resource.khans.io/images/USDClogo.png";
const MunieLogoBackX = "https://resource.khans.io/images/MunieLogoBackX.png";
const SprLogoBackX = "https://resource.khans.io/images/SheepooriLogoBackX.png";
const WhiteUniLogo = "https://resource.khans.io/images/mainImg/unilogo1.png";
const MainFacebookLogo = "https://resource.khans.io/images/mainImg/facebook_logo.png";
const MainTwitterLogo = "https://resource.khans.io/images/mainImg/Twitter_logo.png";
const MainDiscordLogo = "https://resource.khans.io/images/mainImg/discord_logo.png";
const MainTelegramLogo = "https://resource.khans.io/images/mainImg/Telegram_logo.png";
const MainMediumLogo = "https://resource.khans.io/images/mainImg/medium_logo.png";
const MainGithubLogo = "https://resource.khans.io/images/mainImg/github_logo.png";
const MainOffLogo = "https://resource.khans.io/images/mainImg/off_logo.png";
const MainAdLogo = "https://resource.khans.io/images/mainImg/ad_logo.png";
const MainEnterLogo = "https://resource.khans.io/images/mainImg/enter_logo.png";
const MainArrakisLogo = "https://resource.khans.io/images/mainImg/Arrakis-Main-Icon.webp";
const WETHLogo = "https://resource.khans.io/images/WETHlogo.png";
const HANePLogo20 = "https://resource.khans.io/images/HANeP_logo.svg";
const RefreshIcon = "https://resource.khans.io/images/refreshIcon.png";
const QuestionIcon = "https://resource.khans.io/images/questionIcon.png";
const HomeIcon = "https://resource.khans.io/images/homeIcon.svg";
const LocekdIcon = "https://resource.khans.io/images/locekdIcon.svg";
const MailIcon = "https://resource.khans.io/images/mailIcon.png";
const StopSignIcon = "https://resource.khans.io/images/stopSignIcon.svg";
const ClickIcon = "https://resource.khans.io/images/clickIcon.png";
const ClickRedIcon = "https://resource.khans.io/images/clickRedIcon.png";
const LeftArrowIcon = "https://resource.khans.io/images/leftArrowIcon.png";
const RightArrowIcon = "https://resource.khans.io/images/rightArrowIcon.png";
const UpArrowIcon = "https://resource.khans.io/images/upArrowIcon.png";
const DownArrowIcon = "https://resource.khans.io/images/downArrowIcon.png";
const DownArrowListIcon = "https://resource.khans.io/images/downArrowList.png";
const DownArrowSwapIcon = "https://resource.khans.io/images/downArrowSwapIcon.png";
const SearchIcon = "https://resource.khans.io/images/searchIcon.svg";
const YearsLockedIcon = "https://resource.khans.io/images/yearsLockedIcon.svg";
const EyeIcon = "https://resource.khans.io/images/EyeIcon.png";
const poolRightArrow = "https://resource.khans.io/images/poolRightArrow.png";
const wethTokenLogo = "https://resource.khans.io/images/wethTokenLogo.webp";
const poolChLeftArrow = "https://resource.khans.io/images/poolChLeftArrow.png";
const poolChRightArrow = "https://resource.khans.io/images/poolChRightArrow.png";
const clearIcon = "https://resource.khans.io/images/clearIcon.png";
const BlackGitHubIcon = "https://resource.khans.io/images/blackgithubImg.png";
const LaunchPadLogo = "https://resource.khans.io/images/mainImg/launchpadLogo.png";
const WBTCLogo = "https://resource.khans.io/images/WBTCLogo.svg";
const DaigonalArrow = "https://resource.khans.io/images/daigonalArrow.png";
const ethCircleLogo = "https://resource.khans.io/images/ethLogo.svg";
const uniSwapPinkLogo = "https://resource.khans.io/images/uniSwapPink.svg";
const ColorEtheruemLogo = "https://resource.khans.io/images/colorethereumLogo.webp";
const LaunchRightArrow = "https://resource.khans.io/images/launchRightArrow.png";
const LaunchLeftArrow = "https://resource.khans.io/images/launchLeftArrow.png";
const LaunchDownArrow = "https://resource.khans.io/images/launchDownArrow.png";
const WbtcLogoBackX = "https://resource.khans.io/images/wbtcLogoBackX.svg";
const uniSwapV3Pic = "https://resource.khans.io/images/uniSwapV3Pic.png";
const XTcomPic = "https://resource.khans.io/images/XTcomPic.webp";
const LBankPic = "https://resource.khans.io/images/LBankPic.webp";
const YouTubeLogo = "https://resource.khans.io/images/YoutubeLogo.svg";
const IecoAwardLogo = "https://resource.khans.io/images/iECOAWARD.png";
const CertikLogo = "https://resource.khans.io/images/certiklogo.svg";
const IsoBsiLogo = "https://resource.khans.io/images/ISOBsiLogo.png";
const BlackDaigonalArrow = "https://resource.khans.io/images/blackdaigonalArrow.svg";
const MetamaskIcon = "https://resource.khans.io/images/MetamaskIcon.svg";
const MexcPic = "https://resource.khans.io/images/mexc.webp";
const bitMartImg = "https://resource.khans.io/images/bitmartX.png";
const bingXImg = "https://resource.khans.io/images/bitmartBest.png";
const bitmartBestImg = "https://resource.khans.io/images/bingX.png";
const BiconomyBestImg = "https://resource.khans.io/images/BiconomyBestImg.svg";
const QuestNLogo = "https://resource.khans.io/images/QuestNLogo.png";
const TaskOnLogo = "https://resource.khans.io/images/TaskOnLogo.png";
const TaskOnLogo2 = "https://resource.khans.io/images/TaskOnLogo2.png";

export {
  OptimismRedLogo,
  HanLogo,
  SheepooriLogo,
  MusiLogoXBack,
  USDCLogo,
  MunieLogoBackX,
  SprLogoBackX,
  WhiteUniLogo,
  MainFacebookLogo,
  MainTwitterLogo,
  MainDiscordLogo,
  MainTelegramLogo,
  MainMediumLogo,
  MainGithubLogo,
  MainOffLogo,
  MainAdLogo,
  MainEnterLogo,
  MainArrakisLogo,
  WETHLogo,
  HANePLogo20,
  RefreshIcon,
  QuestionIcon,
  HomeIcon,
  LocekdIcon,
  MailIcon,
  StopSignIcon,
  ClickIcon,
  ClickRedIcon,
  LeftArrowIcon,
  RightArrowIcon,
  UpArrowIcon,
  DownArrowIcon,
  DownArrowListIcon,
  DownArrowSwapIcon,
  SearchIcon,
  YearsLockedIcon,
  EyeIcon,
  poolRightArrow,
  wethTokenLogo,
  poolChLeftArrow,
  poolChRightArrow,
  clearIcon,
  BlackGitHubIcon,
  LaunchPadLogo,
  WBTCLogo,
  DaigonalArrow,
  ethCircleLogo,
  uniSwapPinkLogo,
  ColorEtheruemLogo,
  LaunchRightArrow,
  LaunchLeftArrow,
  LaunchDownArrow,
  WbtcLogoBackX,
  uniSwapV3Pic,
  XTcomPic,
  LBankPic,
  YouTubeLogo,
  IecoAwardLogo,
  CertikLogo,
  IsoBsiLogo,
  BlackDaigonalArrow,
  MetamaskIcon,
  MexcPic,
  bitMartImg,
  bingXImg,
  bitmartBestImg,
  BiconomyBestImg,
  QuestNLogo,
  TaskOnLogo,
  TaskOnLogo2,
};
