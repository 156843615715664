import Swal from "sweetalert2";
import { HanBonusStakingAddress, HanBonusStakingABI } from "../../../config/StakingHanChain";
import { ethers } from "ethers";
// import { StakingHanChainContract } from "../../../config/StakingHanchainTest";

function hanStakingUnStakeAct(account, hanWithdrawIndex, walletProvider) {
  return async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum); // Web3Modal을 통해 선택된 지갑의 provider를 사용합니다.
      const signer = provider.getSigner(); // 서명자(Signer)를 가져옵니다.
      const contract = new ethers.Contract(HanBonusStakingAddress, HanBonusStakingABI, signer); // 스마트 컨트랙트 인스턴스를 생성합니다.

      const withdraw = await contract.withdraw(hanWithdrawIndex); // claimReward 함수를 호출합니다.
      await withdraw.wait(); // 트랜잭션이 마이닝될 때까지 기다립니다.
      Swal.fire({
        title: "Success",
        text: "Expired amounts include rewards and are transferred!",
        icon: "success",

        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Fail",
        text: "UnStaking was Fail!",
        icon: "error",

        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };
}

export const hanStakingUnStakeAction = { hanStakingUnStakeAct };
