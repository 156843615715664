import React from "react";
import "./MainMarketSection.scss";
import {
  HANePLogo20,
  HanLogo,
  MainArrakisLogo,
  MunieLogoBackX,
  MusiLogoXBack,
  QuestionIcon,
  SprLogoBackX,
  // uniSwapPinkLogo,
} from "../../assets/_index";

const MainMarketSection = () => {
  return (
    <div className='mainPagePoolContainer'>
      <div className='mainPagePoolBoxSection'>
        <div className='mainPagePoolTitleSection'>
          <p>MARKET</p>
        </div>
        <div className='poolBoxSection'>
          <div className='poolBoxLogoSection'>
            <img src={HanLogo} alt='MunieLogo' />
          </div>
          <div className='poolBoxTxtSection'>
            <span>HAN on Optimism</span>
          </div>
          <div className='poolBoxHANOpBtnSection'>
            <a
              className='poolBoxBtn'
              href='https://app.uniswap.org/explore/tokens/optimism/0x50bce64397c75488465253c0a034b8097fea6578
              '
              target='_blank'
              rel='noreferrer noopener'
            >
              PURCHASE
            </a>
          </div>
        </div>
        <div className='poolBoxSection'>
          <div className='poolBoxLogoSection'>
            <img src={HANePLogo20} alt='ArrakisLogo' />
          </div>
          <div className='poolBoxTxtSection'>
            <span>HANeP on Optimism</span>
          </div>
          <div className='poolBoxHANePOpBtnSection'>
            <a
              className='poolBoxBtn'
              href='https://app.uniswap.org/explore/tokens/optimism/0xc3248a1bd9d72fa3da6e6ba701e58cbf818354eb'
              target='_blank'
              rel='noreferrer noopener'
            >
              PURCHASE
            </a>
          </div>
        </div>

        <div className='poolBoxSection'>
          <div className='poolBoxLogoSection'>
            <img src={MunieLogoBackX} alt='MunieLogo' />
          </div>
          <div className='poolBoxTxtSection'>
            <span>Munie NFT</span>
          </div>
          <div className='poolBoxMunieBtnSection'>
            <a
              className='poolBoxBtn'
              href='https://opensea.io/collection/munie'
              target='_blank'
              rel='noreferrer noopener'
            >
              PURCHASE
            </a>
          </div>
        </div>
        <div className='poolBoxSection'>
          <div className='poolBoxLogoSection'>
            <img src={MusiLogoXBack} alt='MunieLogo' />
          </div>
          <div className='poolBoxTxtSection'>
            <span>MusiKhan Launchpad</span>
          </div>
          <div className='tooltip-main-spri-container'>
            <a
              href='https://github.com/hanchain-paykhan/launchpad.khans.io/tree/main/musikhan#musikhan-launchpad-on-optimism'
              target='_blank'
              rel='noreferrer noopener'
            >
              <img src={QuestionIcon} className='info-icon material-main-spri-icons' alt='QuestionIcon'></img>
            </a>
          </div>
          <div className='poolBoxLaunchPadBtnSection'>
            <a
              className='poolBoxBtn'
              href='https://launchpad.khans.io/musikhan'
              target='_blank'
              rel='noreferrer noopener'
            >
              PURCHASE
            </a>
          </div>
        </div>
        <div className='poolBoxSection'>
          <div className='poolBoxLogoSection'>
            <img src={SprLogoBackX} alt='SprLogo' />
          </div>
          <div className='poolBoxTxtSection'>
            <span>Sheepoori SPR NFT</span>
          </div>
          <div className='poolBoxSpriBtnSection'>
            <a
              className='poolBoxBtn'
              href='https://opensea.io/collection/sheepoori'
              target='_blank'
              rel='noreferrer noopener'
            >
              PURCHASE
            </a>
          </div>
        </div>
        <div className='poolBoxSection'>
          <div className='poolBoxLogoSection'>
            <img src={MainArrakisLogo} alt='ArrakisLogo' />
          </div>
          <div className='poolBoxTxtSection'>
            <span>WETH-V3-HANeP</span>
          </div>
          <div className='poolBoxWethV3HanePBtnSection'>
            <a className='poolBoxBtn' href='/addpool' target='_blank' rel='noreferrer noopener'>
              ADD POOL
            </a>
          </div>
        </div>
        {/* <div className='poolBoxSection'>
          <div className='poolBoxLogoSection'>
            <img src={uniSwapPinkLogo} alt='MunieLogo' />
          </div>
          <div className='poolBoxTxtSection'>
            <span>USDC-V2-HAN</span>
          </div>
          <div className='poolBoxUsdcBtnSection'>
            <a
              className='poolBoxBtn'
              href='https://app.uniswap.org/#/add/v2/0x0c90C57aaf95A3A87eadda6ec3974c99D786511F/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
              target='_blank'
              rel='noreferrer noopener'
            >
              ADD POOL
            </a>
          </div>
        </div>
        <div className='poolBoxSection'>
          <div className='poolBoxLogoSection'>
            <img src={uniSwapPinkLogo} alt='ArrakisLogo' />
          </div>
          <div className='poolBoxTxtSection'>
            <span>USDC-V2-HANeP</span>
          </div>
          <div className='poolBoxHanEpBtnSection'>
            <a
              className='poolBoxBtn'
              href='https://app.uniswap.org/#/add/v2/0x5052fa4a2a147eaAa4c0242e9Cc54a10A4f42070/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
              target='_blank'
              rel='noreferrer noopener'
            >
              ADD POOL
            </a>
          </div>
        </div> */}
        <div className='poolBoxSection'>
          <div className='poolBoxLogoSection'>
            <img src={MainArrakisLogo} alt='ArrakisLogo' />
          </div>
          <div className='poolBoxTxtSection'>
            <span>WETH-V3-HAN</span>
          </div>
          <div className='poolBoxWethBtnSection'>
            <a
              className='poolBoxBtn'
              href='https://beta.arrakis.finance/vaults/0x3fa8cee6795220ac25dd35d4d39ec306a3e4fb3f'
              target='_blank'
              rel='noreferrer noopener'
            >
              ADD POOL
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MainMarketSection);
