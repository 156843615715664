import axios from "axios";

function allSPRV2StakedViewAct() {
  return async (dispatch) => {
    try {
      // const getSPRV2StakingTokenImgVideoUrlToBackApi = await axios.get("http://localhost:4000/block/sprV2AllStakedView");
      const getSPRV2StakingTokenImgVideoUrlToBackApi = await axios.get("https://back.khans.io/block/sprV2AllStakedView");

      dispatch({
        type: "GET_SPRV2_ALL_TOKEN_VIEW",
        payload: {
          getSPRV2StakingTokenIdImgVideoUrl: getSPRV2StakingTokenImgVideoUrlToBackApi.data,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export const allSPRV2StakedViewAction = { allSPRV2StakedViewAct };
