import Swal from "sweetalert2";
import { StakingTokenAddress, StakingTokenABI, StakingUniV2Address } from "../../../config/new/StakingUniV2Config";
import { ethers } from "ethers";

function uniV2ApproveAct(account, hanChainStakingNum, walletProvider) {
  return async (dispatch) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum); // Web3Modal을 통해 선택된 지갑의 provider를 사용합니다.
      const signer = provider.getSigner(); // 서명자(Signer)를 가져옵니다.
      const contract = new ethers.Contract(StakingTokenAddress, StakingTokenABI, signer); // 스마트 컨트랙트 인스턴스를 생성합니다.
      const approve = await contract.approve(StakingUniV2Address, hanChainStakingNum); // claimReward 함수를 호출합니다.
      await approve.wait(); // 트랜잭션이 마이닝될 때까지 기다립니다.

      // const approve = await StakingTokenContract.methods
      //   .approve(StakingUniV2Address, hanChainStakingNum)
      //   .send({ from: account });

      Swal.fire({
        title: "Success",
        text: "Approve was successful!",
        icon: "success",

        confirmButtonColor: "#3085d6", // confrim 버튼 색깔 지정
        confirmButtonText: "OK", // confirm 버튼 텍스트 지정
        // showCancelButton: true,
        // cancelButtonText: '취소',
        // cancelButtonColor: '#d33',

        // reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      dispatch({
        type: "UNIV2_STAKING_APPORVE_SUCCESS",
        payload: {
          successUniV2Approve: true,
        },
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Fail",
        text: "Approve was Fail!",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };
}

export const uniV2ApproveAction = { uniV2ApproveAct };
