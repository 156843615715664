import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import MainPage from "./pages/MainPage";
import MainLoading from "./components/GloBal/MainLoading";
// const MainPage = lazy(() => import(/* webpackChunkName: "mainPage" */ "./pages/MainPage"));
const Rakis6StakingPage = lazy(() => import(/* webpackChunkName: "mainPage" */ "./pages/Rakis6StakingPage"));
const SprStakingPage = lazy(() => import(/* webpackChunkName: "mainPage" */ "./pages/SprStakingPage"));
const AirDropSignInPage = lazy(() => import("./pages/airDrop/AirDropSignInPage"));
const AirDropSignUpPage = lazy(() => import("./pages/airDrop/AirDropSignUpPage"));
const HanEPlatFromPage = lazy(() => import("./pages/airDrop/HanEPlatFromPage"));
const HanStakingPage = lazy(() => import("./pages/HanStakingPage"));
const Uni2V2StakingPage = lazy(() => import("./pages/Uni2V2StakingPage"));
const OldHanEPlatFromPage = lazy(() => import("./pages/oldPages/OldHanEPlatFromPage"));
const OldHanEplatSignInPage = lazy(() => import("./pages/airDrop/OldHanEplatSignInPage"));
const OldHanEplatSingUpPage = lazy(() => import("./pages/airDrop/OldHanEplatSingUpPage"));
const HanTokenomicsPage = lazy(() => import("./pages/HanTokenomicsPage"));
const HanEpTokenomicsPage = lazy(() => import("./pages/HanEpTokenomicsPage"));
const Rakis49PoolPage = lazy(() => import("./pages/Rakis49PoolPage"));
// const MusikhanTestPage = lazy(() => import("./pages/MusikhanTestPage"));
const DashBoardPage = lazy(() => import("./pages/DashBoardPage"));
const DashBoardSignInPage = lazy(() => import("./pages/dashboard/DashBoardSignInPage"));
const DashBoardSignUpPage = lazy(() => import("./pages/dashboard/DashBoardSignUpPage"));
const StakingPage = lazy(() => import("./pages/StakingPage"));
const LaunchPadPage = lazy(() => import("./pages/LaunchPadPage"));
const LegalDisclaimerPage = lazy(() => import("./pages/LegalDisclaimerPage"));
// const TestLoading = lazy(() => import("./pages/TestLoading"));

function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className='allPageLodingContainer'>
            <MainLoading />
          </div>
        }
      >
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/rakis6' element={<Rakis6StakingPage />} />
          <Route path='/spr' element={<SprStakingPage />} />
          <Route path='/hanbonus' element={<HanStakingPage />} />
          <Route path='/univ2' element={<Uni2V2StakingPage />} />
          <Route path='/hanep' element={<HanEPlatFromPage />} />
          <Route path='/hanep/old' element={<OldHanEPlatFromPage />} />
          <Route path='/hanep/signin' element={<AirDropSignInPage />} />
          <Route path='/hanep/signup' element={<AirDropSignUpPage />} />
          <Route path='/hanep/old/signin' element={<OldHanEplatSignInPage />} />
          <Route path='/hanep/old/signup' element={<OldHanEplatSingUpPage />} />
          <Route path='/hanscan' element={<HanTokenomicsPage />} />
          <Route path='/hanepscan' element={<HanEpTokenomicsPage />} />
          <Route path='/addpool' element={<Rakis49PoolPage />} />
          {/* <Route path='/test' element={<MusikhanTestPage />} /> */}
          <Route path='/dashboard' element={<DashBoardPage />} />
          <Route path='/dashboard/signin' element={<DashBoardSignInPage />} />
          <Route path='/dashboard/signup' element={<DashBoardSignUpPage />} />
          <Route path='/staking' element={<StakingPage />} />
          <Route path='/launchpad' element={<LaunchPadPage />} />
          <Route path='/LegalDisclaimer' element={<LegalDisclaimerPage />} />
          {/* <Route path='/TestLoading' element={<TestLoading />} /> */}
          {/* <Route path='/loading' element={<Loading />} /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
